import axios from "axios";

import { url, headers } from "../../constants";
import swal from "sweetalert";

export const workerLogin = (payload, history) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/worker/login`, payload);
      if (res) {
        console.log(res.data);
        const res2 = await axios.get(
          `${url}/admin/get-admin-id/${res.data.doc.admin}`
        );
        if (res2) {
          console.log(res2.data.admin.active);
          if (res2.data.admin.active) {
            if (res.data.doc.status) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("workerId", res.data.doc._id);
              localStorage.setItem("workerName", res.data.doc.name);
              localStorage.setItem("role", "WORKER");
              localStorage.setItem("selectedAdminId", res.data.doc.admin);
              localStorage.setItem("mainAdminId", res.data.doc.admin);
              // localStorage.setItem('selectedTableId', tableId);
              swal("", res.data.message, "success");
              history.replace("/user/tables");
              window.location.reload();
            } else {
              swal("", "You are Inactive by the Admin", "error");
            }
          } else {
            swal("", "Sorry! The Admin is Inactive/Blocked", "error");
          }
        }
      }
    } catch (err) {
      swal("", "Wrong Email or Password", "error");
      console.log(err);
    }
  };
};

export const adminLogin = async (id, history) => {
  const res = await axios.get(`${url}/admin/get-admin-id/${id}`, {
    headers: headers,
  });
  console.log(res.data.admin);
  if (res) {
    if (res.data.admin.active) {
      // localStorage.setItem("token", res.data.token);
      localStorage.setItem("workerId", res.data.admin._id);
      // localStorage.setItem("workerName", res.data.admin.name);
      localStorage.setItem("workerName", "ADMIN");
      localStorage.setItem("role", "WORKER");
      localStorage.setItem("selectedAdminId", res.data.admin._id);
      localStorage.setItem("mainAdminId", res.data.admin._id);
      // localStorage.setItem('selectedTableId', tableId);
      localStorage.setItem("userId", res.data.admin.userId);
      swal("", "Admin Logged In", "success").then(() => {
        history.replace("/user");
        window.location.reload();
      });
    } else {
      swal("", "Sorry! The Admin is Inactive/Blocked", "error");
    }
  }
};

export const getTableTabs = () => async (dispatch) => {
  try {
    const adminId = localStorage.getItem("mainAdminId");
    const res = await axios.get(`${url}/admin/table-names/${adminId}`, {
      headers: headers,
    });
    if (res) {
      dispatch({
        type: "TABLE_NAMES",
        payload: res.data.tables,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAdminTableOrders = (tableId) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/table-order`, { tableId });
    if (res) {
      dispatch({
        type: "TABLE_ORDERS",
        payload: res.data.orders,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
