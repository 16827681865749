import { Table, Select, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminTableOrders } from "../redux";
import OrdersModals from "../components/Modals/TableOrderModal";

const Orders = ({ tableId }) => {
  const columns = [
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Order Name",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Unit Price",
      dataIndex: "unit",
      key: "unit",

      sorter: (a, b) => a.unit - b.unit,
      ellipsis: true,
    },
    {
      title: "Total Price",
      dataIndex: "total",
      key: "total",

      sorter: (a, b) => a.total - b.total,
      //   sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      //   filteredValue: filteredInfo.address || null,
    },
  ];
  const { 
    //Option
   } = Select;
  const { Title } = Typography;
  const dispatch = useDispatch();

  // Show Entries
  function handleChange(value) {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  }

  // Table Data
  const ordersList = useSelector((state) => state.workerReducer.tableOrders);
  useEffect(() => {
    dispatch(getAdminTableOrders(tableId));
  }, []);

  let orders = [];
  
    ordersList &&
    ordersList.map((details) => {
      details.products.map((item) => {
        orders.push({
          key: item._id,
          order: item.product.name,
          quantity: item.quantity,
          unit: item.product.price,
          total: parseInt(item.product.price) * item.quantity,
          customer: details.userName ? details.userName : details.user.name,
          action: <OrdersModals details={details} />,
        });
      });
    });

  // orders = data;

  return (
    <div className="customers-list">
      <Title level={2}>Orders</Title>

      <div className="table-container">
        <Table
          columns={columns}
          // loading={orders && orders.length > 0 ? false : true}
          dataSource={orders}
          onChange={handleChange}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </div>
  );
};

export default Orders;
