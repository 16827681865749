import { Table, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminTableOrders } from "../redux";

const CustomersList = ({ tableId }) => {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      //   filters: [
      //     { text: "Joe", value: "Joe" },
      //     { text: "Jim", value: "Jim" },
      //   ],
      //   //   filteredValue: filteredInfo.name || null,
      //   onFilter: (value, record) => record.name.includes(value),
      //   sorter: (a, b) => a.name.length - b.name.length,
      //   //   sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      //   ellipsis: true,
    },
    {
      title: "Total Bill",
      dataIndex: "totalBill",
      key: "totalBill",
      sorter: (a, b) => a.totalBill - b.totalBill,
      //   sortOrder: sortedInfo.columnKey === "age" && sortedInfo.order,
      ellipsis: true,
    },
  ];

  const customerList = useSelector((state) => state.workerReducer.tableOrders);
  useEffect(() => {
    dispatch(getAdminTableOrders(tableId));
  }, []);

  console.log(tableId);

  return (
    <div className="customers-list">
      <div className="customer-search">
        <Title level={2}>Customers List</Title>
        {/* <Input
          onChange={(e) => onSearchCustomer(e)}
          placeholder="search by name"
          className="search-bar"
          allowClear={() => onSearchCustomer("")}
        /> */}
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          scroll={{ x: 600 }}
          // loading={customerList && customerList.length > 0 ? false : true}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
          dataSource={
            customerList && customerList.length > 0
              ? customerList.map((details) => {
                  return {
                    key: details._id,
                    name: details.userName
                      ? details.userName
                      : details.user.name,
                    totalBill: details.amount,
                    // pay: (
                    //   <CustomerPayModal
                    //     details={details}
                    //     customerList={customerList}
                    //   />
                    // ),
                  };
                })
              : null
          }
        />
      </div>
    </div>
  );
};

export default CustomersList;
