import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import restaurant from "../assets/marker.png";
import { getAllAdmins } from "../redux";

// const svg = new Icon({
//   iconUrl: restaurant,
//   iconSize: [30, 30],
// });

// const buttonStyle = {
//   margin: "20px 0 0 0",
//   letterSpacing: "1px",
// };

const MapComp = (props) => {
  // const [lat, setLat] = useState(24.918);
  // const [long, setLong] = useState(67.0971);
  const dispatch = useDispatch();

 // const [admin, setAdmin] = useState({});
  const [alladmin, setAllAdmin] = useState([]);

  const [myLoc, setMyLoc] = useState([0, 0]);

  // const [lat, setLat] = useState(24.8693606);
  // const [long, setLong] = useState(67.082216);

  useEffect(async () => {
    //const details = await dispatch(getAdminDetails());
    const allAdminsData = await dispatch(getAllAdmins());
    setAllAdmin(allAdminsData);
    // setAdmin(details);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setMyLoc([position.coords.latitude, position.coords.longitude]);
      },
      (e) => {
        console.log(e);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     setLat(position.coords.latitude);
    //     setLong(position.coords.longitude);
    //   });
    // } else {
    //   swal("", "Geolocation is not supported by this browser.", "warning");
    // }
  }, []);

  // console.log(lat, long);

  // const svg = (icon) => {
  //   return new Icon({
  //     // iconUrl: admin && admin.logo ? admin.logo : restaurant,
  //     iconUrl: icon,
  //     iconSize: [40, 25],
  //   });
  // };
  const svg2 = new Icon({
    iconUrl: restaurant,
    iconSize: [30, 30],
  });

  // console.log(alladmin);

  return (
    <div>
      <MapContainer
        key={Math.random() * 100 + "as9di214b"}
        center={myLoc}
        zoom={20}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {/* admin location marker */}
        {alladmin &&
          alladmin.length > 0 &&
          alladmin.map((admin, index) => {
            return (
              admin.location && (
                <Marker
                  position={[
                    admin && admin.location && admin.location.lat,
                    // ? admin.location.lat
                    // : lat,
                    admin && admin.location && admin.location.lng,
                    // ? admin.location.lng
                    // : long,
                  ]}
                  // draggable
                  // icon={svg(admin.logo)}
                >
                  <Popup>
                    {admin && (
                      <div>
                        <p>Name : {admin && admin.name}</p>
                        <p>
                          Contact :
                          <a href={`tel:${admin && admin.contactNumber}`}>
                            {admin && admin.contactNumber}
                          </a>
                        </p>
                        <p>
                          Location :{" "}
                          {`lat: ${
                            admin && admin.location && admin.location.lat
                          } , long: ${
                            admin && admin.location && admin.location.lng
                          }`}
                        </p>
                        <a onClick={() => props.selectAdmin(alladmin[index])}>
                          Click for Details
                        </a>
                      </div>
                    )}
                  </Popup>
                </Marker>
              )
            );
          })}

        {/* <Marker
          position={[
            admin && admin.location && admin.location.lat
              ? admin.location.lat
              : lat,
            admin && admin.location && admin.location.lng
              ? admin.location.lng
              : long,
          ]}
          // draggable
          icon={svg}
        >
          <Popup>
            {admin && (
              <div>
                <p>Name : {admin && admin.name}</p>
                <p>
                  Contact :
                  <a href={`tel:${admin && admin.contactNumber}`}>
                    {admin && admin.contactNumber}
                  </a>
                </p>
                <p>
                  Location :{" "}
                  {`lat: ${
                    admin && admin.location && admin.location.lat
                  } , long: ${admin && admin.location && admin.location.lng}`}
                </p>
              </div>
            )}
          </Popup>
        </Marker> */}

        {/* user location marker */}

        <Marker
          // position={[lat && lat, long && long]}
          position={myLoc}
          // draggable
          icon={svg2}
        >
          <Popup>
            <div>
              <p>User Current Location</p>
              {/* <p>
                  Contact :
                  <a href={`tel:${admin && admin.contactNumber}`}>
                    {admin && admin.contactNumber}
                  </a>
                </p>
                <p>
                  Location :{" "}
                  {`lat: ${
                    admin && admin.location && admin.location.lat
                  } , long: ${admin && admin.location && admin.location.lng}`}
                </p> */}
            </div>
          </Popup>
        </Marker>
      </MapContainer>

      {/* <Button
        type="primary"
        style={buttonStyle}
        // onClick={liveLocation}
      >
        Show my live location
      </Button> */}
    </div>
  );
};

export default MapComp;
