import { useEffect, useState } from "react";
import { Form, Input, Button, Select, Checkbox } from "antd";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";

import logo from "../assets/qrlogo.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries, getAllCitiesbyCountry, userSignup } from "../redux";

const { Option } = Select;

const SignIn = () => {
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const { adminId, tableId } = useParams();

  console.log(terms, policy);

  const allCountries = useSelector(
    (state) => state.generalReducer.allCountries
  );

  const allCities = useSelector((state) => state.generalReducer.allCities);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  const onChange = (value) => {
    // console.log(`selected country,  ${value}`);
    dispatch(getAllCitiesbyCountry(value));
  };

  console.log(allCities);

  const onFinish = (values) => {
    if (terms && policy) {
      if (values.confirmPassword === values.password) {
        let payload = {
          email: values.email,
          password: values.password,
          mobile: values.phone,
          country: values.country,
          city: values.district,
          name: values.name,
          surname: values.surname,
        };
        console.log(payload);
        dispatch(userSignup(payload, history, adminId, tableId));
      } else {
        swal("", "Password doesn't matched", "error");
      }
    } else {
      swal("", "Accept Terms and Policies", "warning");
    }
  };

  // if (localStorage.hasOwnProperty("adminEmail")) {
  //   return <Redirect to="/" />;
  // } else {
  return (
    <div className="signin">
      <Link to="/">
        <img src={logo} alt="logo" width="auto" height="100px" />
        {/* <p>QRTREAT</p> */}
      </Link>
      <Form
        name="login"
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <h2>User Register</h2>

        <Form.Item name="name" label="Name">
          <Input required autoComplete="off" placeholder="Name..." />
        </Form.Item>
        <Form.Item name="surname" label="Surname">
          <Input required autoComplete="off" placeholder="Surname..." />
        </Form.Item>
        <Form.Item
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
          name="email"
          label="Email"
        >
          <Input required autoComplete="off" placeholder="Enter Email..." />
        </Form.Item>
        <Form.Item name="password" label="Password">
          <Input.Password required type="password" placeholder="Password..." />
        </Form.Item>
        <Form.Item name="confirmPassword" label="Confirm Password">
          <Input.Password required type="password" placeholder="Password..." />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input required autoComplete="off" placeholder="Number..." />
        </Form.Item>
        <Form.Item name="country" label="Country">
          <Select
            showSearch
            style={{ width: "100%" }}
            allowClear
            placeholder="Select Country"
            onChange={onChange}
          >
            {allCountries &&
              allCountries.map((country, index) => (
                <Option key={index} value={country}>
                  {country}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="district" label="District">
          <Select
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allCities &&
              allCities.map((city) => (
                <Option key={city._id} value={city.city}>
                  {city.city}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Checkbox value="terms" onChange={(e) => setTerms(e.target.checked)}>
            Accept Terms & Conditions
          </Checkbox>
          <br />
          <Checkbox
            value="policy"
            onChange={(e) => setPolicy(e.target.checked)}
          >
            Accept Privicy Policy
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // onClick={Login}
          >
            {/* <Link
              to="/"
            onClick={() => dispatch(login(email, password, history))}
            > */}
            Sign Up
            {/* </Link> */}
          </Button>
        </Form.Item>

        <div className="bottom-links">
          <Link to="/user-login/1/1" className="login-forgot">
            Already have an Account? Sign In
          </Link>
        </div>
      </Form>
    </div>
  );
  // }
};

export default SignIn;
