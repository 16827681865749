import * as promotionTypes from "../types/promotionTypes";

const initialState = {
  freePromotions: [],
  productPromotions: [],
  myPromotions: [],
};

const promotionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case promotionTypes.GET_MY_PROMOTIONS:
      return {
        ...state,
        myPromotions: payload,
      };
    case promotionTypes.GET_FREE_PROMO:
      return {
        ...state,
        freePromotions: payload,
      };
    case promotionTypes.GET_PRODUCT_PROMO:
      return {
        ...state,
        productPromotions: payload,
      };

    default:
      return state;
  }
};

export default promotionReducer;
