export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ACTIVE_ORDERS = "GET_ACTIVE_ORDERS";
export const GET_PRODUCT = "GET_PRODUCT";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE = "REMOVE";
export const EMPTY_CART = "EMPTY_CART";
export const USER_PROMOTIONS = "USER_PROMOTIONS";

export const ADD_QUANTITY = "ADD_QUANTITY";
export const SUBTRACT_QUANTITY = "SUBTRACT_QUANTITY";
export const GET_SHARED_ORDER = "GET_SHARED_ORDER";
export const IS_SELECTED = "IS_SELECTED";
