import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  var ID = localStorage.getItem("userId");
  var role = localStorage.getItem("role");

  return (
    <Route
      {...rest}
      render={(props) =>
        ID && localStorage.hasOwnProperty("userId") ? (
          <Component {...props} />
        ) : role === "GUEST" || role === "WORKER" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/user-login/1/1" />
        )
      }
    />
  );
};

export default PrivateRoute;
