import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

// dashboard pages
import LandingPage from "./pages/LandingPage";
import UserDashboard from "./pages/UserDashboard";
import CategoryListing from "./pages/CategoryListing";
import CategoryItem from "./pages/CategoryItem";
import OrderHistory from "./pages/OrderHistory";
import FindVenue from "./pages/FindVenue";
import AttendedVenue from "./pages/AttendedVenue";
import Settings from "./pages/AccountSettings";
import Cart from "./pages/Cart";
import UserLogin from "./pages/UserLogin";
import WorkerLogin from "./pages/WorkerLogin";
import UserRegister from "./pages/UserRegister";
import ForgetPassUser from "./pages/ForgetPassUser";
import Promotions from "./pages/Promotions";
import CodeVerify from "./pages/CodeVerify";
import Tables from "./pages/Tables";
import VeirfyCode from "./pages/VeirfyCode";

import PrivateRoutes from "./PrivateRoutes";
import { useSelector } from "react-redux";

const Routes = () => {
  const role = localStorage.getItem("role");
  const tableSelected = useSelector(
    (state) => state.dashboardReducer.tableSelected
  );

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          {/* <PrivateRoutes path="/user" exact component={UserDashboard} /> */}
          <PrivateRoutes
            path="/user/category/:id"
            exact
            component={CategoryListing}
          />
          <PrivateRoutes
            path="/user/category/item/:id"
            exact
            component={CategoryItem}
          />
          <PrivateRoutes path="/user/history" exact component={OrderHistory} />
          <PrivateRoutes path="/user/find-venue" exact component={FindVenue} />
          <PrivateRoutes
            path="/user/attended-venue"
            exact
            component={AttendedVenue}
          />
          <PrivateRoutes path="/user/settings" exact component={Settings} />
          <PrivateRoutes path="/user/cart" exact component={Cart} />
          <PrivateRoutes path="/user/promotions" exact component={Promotions} />
          <Route path="/forget/user" exact component={ForgetPassUser} />
          <PrivateRoutes path="/code-verify" exact component={CodeVerify} />
          <Route
            path="/user-register/:adminId/:tableId"
            exact
            component={UserRegister}
          />
          <Route
            path="/user-login/:adminId/:tableId"
            exact
            component={UserLogin}
          />
          <Route path="/user-login" exact component={UserLogin} />
          <Route path="/worker-login" exact component={WorkerLogin} />
          <Route path="/admin/:id" exact component={WorkerLogin} />
          <Route path="/user/tables" exact component={Tables} />
          <Route path="/verify-code" exact component={VeirfyCode} />
          {role === "WORKER" ? (
            tableSelected ? (
              <>
                <PrivateRoutes path="/user/cart" exact component={Cart} />
                <PrivateRoutes path="/user" exact component={UserDashboard} />
              </>
            ) : (
              <Redirect to="/user/tables" />
              // <Route path="/user/tables" exact component={Tables} />
            )
          ) : (
            <>
              <PrivateRoutes path="/user/cart" exact component={Cart} />
              <PrivateRoutes path="/user" exact component={UserDashboard} />
            </>
          )}
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
