import React, { useState } from "react";
import { Modal, Button, Rate, Input, Typography } from "antd";
import { useDispatch } from "react-redux";
import { rateProduct } from "../../redux";
import swal from "sweetalert";

const RateOrder = ({ product, orderId }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldRate, setShouldRate] = useState(true);
  const [shouldComment, setShouldComment] = useState(true);
  const [rate, setRate] = useState(2.5);
  const [comment, setComment] = useState("");

  const [loading, setLoading] = useState(false);

  ///const userId = localStorage.getItem("userId");
  const showModal = () => {
    setIsModalVisible(true);
    checkRating();
    console.log(product);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const checkRating = () => {
    product &&
      product.rating.length > 0 &&
      product.rating.map((rate) => {
        if (rate.order === orderId) {
          setShouldRate(false);
          setRate(rate.rate);
        }
      });
    product &&
      product.comments.length > 0 &&
      product.comments.map((comment) => {
        if (comment.order === orderId) {
          setShouldComment(false);
          setComment(comment.comment);
        }
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRateChange = (rating) => {
    setRate(rating);
  };
  const submitRating = async () => {
    setLoading(true);
    if (comment !== "") {
      await dispatch(rateProduct(rate, comment, product._id, orderId));
      setLoading(false);
      handleCancel();
    } else {
      setLoading(false);
      swal("", "Comment is required", "warning");
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Rate Order
      </Button>
      <Modal
        title="Rate Product/Item"
        visible={isModalVisible}
        onOk={handleOk}
        width="50%"
        footer={
          <Button
            disabled={!(shouldComment && shouldRate) ? true : false}
            type="primary"
            onClick={submitRating}
            loading={loading}
          >
            Submit
          </Button>
        }
        onCancel={handleCancel}
      >
        {!shouldRate && !shouldComment && (
          <Typography.Title
            level={4}
            style={{ textAlign: "center", color: "red" }}
          >
            You Have Already rated This Product
          </Typography.Title>
        )}
        <Rate
          disabled={shouldRate ? false : true}
          allowHalf
          onChange={onRateChange}
          defaultValue={rate}
        />{" "}
        {!shouldRate && "Already Rated"}
        <p style={{ marginTop: "20px" }}>Enter your Review/Comments</p>
        <Input.TextArea
          rows={5}
          value={comment}
          disabled={shouldComment ? false : true}
          style={{ resize: "none", marginBottom: "20px" }}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default RateOrder;
