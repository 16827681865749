import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Tag, Row, Col } from "antd";

import Layout from "../layout/DashboardLayout";
import { getTableTabs, getAdminTableOrders, isTableSelected } from "../redux";
import CustomersList from "../components/CustomerList";
import TableOrders from "../components/TableOrders";
import swal from "sweetalert";

//const { TabPane } = Tabs;
const { CheckableTag } = Tag;

const Tables = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTableName, setTableName] = useState("");

  const tableTabs = useSelector((state) => state.workerReducer.tables);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const getTables = async () => {
      await dispatch(getTableTabs());
    };
    getTables();
    // setSelectedTags(tableTabs && tableTabs[0] && tableTabs[0]._id);
    tagChange(tableTabs && tableTabs[0] && tableTabs[0]._id);
  }, []);

  console.log(selectedTags[0]);

  const callback = (key) => {
    dispatch(getAdminTableOrders(key));
  };

  const tagChange = (tag, checked) => {
    callback(tag);
    const nextSelectedTags = [tag];
    setSelectedTags(nextSelectedTags);
  };

  return (
    <Layout active="tables">
      <div className="table-tabs">
        <Row gutter={[10, 10]} className="tables">
          {tableTabs &&
            tableTabs.length > 0 &&
            tableTabs.map((tabName, i) => {
              return (
                <Col key={tabName._id} xs={12} md={6} lg={4}>
                  <CheckableTag
                    className="tabs-checked"
                    key={tabName._id}
                    checked={
                      selectedTags && selectedTags.indexOf(tabName._id) > -1
                    }
                    onChange={() => {
                      tagChange(tabName._id);
                      setTableName(tabName.name);
                    }}
                  >
                    {tabName.name}
                  </CheckableTag>
                </Col>
              );
            })}
        </Row>

        <div>
          <Button
            onClick={() => {
              if (selectedTags?.length > 0) {
                localStorage.setItem(
                  "selectedTableId",
                  selectedTags && selectedTags
                );
                dispatch(isTableSelected(true));
                history.push("/user");
              } else {
                swal("please select table", "", "error");
              }
            }}
            type="primary"
            style={{
              marginLeft: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Place Order
          </Button>
          <CustomersList tableId={selectedTags && selectedTags[0]} />
          <TableOrders tableId={selectedTags && selectedTags[0]} />
        </div>

        {/* <Tabs className="table-tabs" onChange={callback}>
          {tableTabs &&
            tableTabs.length > 0 &&
            tableTabs.map(
              (tabs) =>
                tabs.active && (
                  <TabPane tab={tabs.name} key={tabs._id}>
                    <Button
                      onClick={() => {
                        localStorage.setItem("selectedTableId", tabs._id);
                        swal(
                          `${tabs.name} Selected`,
                          `Now You can Place Order`,
                          "success"
                        ).then(() => {
                          history.push("/user");
                        });
                      }}
                      type="primary"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Select This Table
                    </Button>
                    <CustomersList tableId={tabs._id} />
                    <TableOrders tableId={tabs._id} />
                  </TabPane>
                )
            )}
        </Tabs> */}
      </div>
    </Layout>
  );
};

export default Tables;
