import axios from "axios";

import { url } from "../../constants";
import * as generalTypes from "../types/generalTypes";
const userId = localStorage.getItem("userId");

export const getAllCountries = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/city/get-countries`);
      if (res) {
        // console.log(res);
        dispatch({
          type: generalTypes.GET_ALL_COUNTRIES,
          payload: res.data.countries,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCitiesbyCountry = (country) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/city/get-cities`, { country });
      if (res) {
        // console.log(res);
        dispatch({
          type: generalTypes.GET_ALL_CITIES,
          payload: res.data.cities,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getUserInfo = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${url}/user/get-user/${userId}`);
      if (res) {
        console.log(res.data);
        dispatch({
          type: generalTypes.GET_USER_INFO,
          payload: res.data.user,
        });
        return res.data.user;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllAdmins = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/get-admins`);
      if (res) {
        // console.log(res.data);
        return res.data.admins;
        // dispatch({
        //   type: generalTypes.GET_USER_INFO,
        //   payload: res.data.user,
        // });
        // return res.data.user;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedAdminTables = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/admin/get-table-count`, {
        adminId: id,
      });
      if (res) {
        return {
          tables: res.data.tablesActive,
          tablesActive: res.data.uniqueTables,
        };
      }
    } catch (err) {
      console.log(err);
    }
  };
};
