import React, { useState } from "react";
import { Modal, Button, Table } from "antd";

import RateOrder from "./RateOrder";

const ViewProducts = ({ products, orderId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    console.log(products);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Extra Products",
      dataIndex: "extra",
      key: "extra",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ height: "32px", padding: "4px 10px" }}
      >
        View Order
      </Button>
      <Modal
        title="Order Details"
        visible={isModalVisible}
        onOk={handleOk}
        footer={false}
        width={"80%"}
        onCancel={handleCancel}
      >
        <Table
          dataSource={
            products &&
            products.length > 0 &&
            products.map((order) => ({
              key: order._id,
              name: order.product && order.product.name,
              quantity: order.quantity,
              extra: order.extraProducts.map((extra) => {
                if (extra.active) return `${extra.productName},`;
              }),
              rate: <RateOrder product={order.product} orderId={orderId} />,
            }))
          }
          //   expandable={{
          //     expandedRowRender: (record) => (
          //       <RateOrder />
          //     ),
          //   }}
          columns={columns}
          pagination={{
            pageSizeOptions: ["10", "20", "40", "70"],
            showSizeChanger: true,
          }}
          scroll={{ x: 600 }}
          sticky
        />
      </Modal>
    </>
  );
};

export default ViewProducts;
