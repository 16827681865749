const initialState = {
  tables: [],
  tableOrders: [],
};

const workerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'TABLE_NAMES':
      return { ...state, tables: payload };
    case 'TABLE_ORDERS':
      return { ...state, tableOrders: payload };
    default:
      return state;
  }
};

export default workerReducer;
