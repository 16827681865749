import { Typography, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import RateVenue from "../components/Modals/RateVenue";
import ProductsModal from "../components/Modals/ProductsModal";
import Layout from "../layout/DashboardLayout";
import { getOrdersHistory } from "../redux";

const OrderHistory = () => {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.historyReducer.orderHistory);
  // console.log(orders);
  const [isOrder, setIsOrder] = useState(null);

  console.log(isOrder);

  useEffect(() => {
    dispatch(getOrdersHistory());
  }, []);

  setTimeout(() => {
    setIsOrder(orders);
  }, 2000);

  const columns = [
    {
      title: "Placed On",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Restaurant",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Table",
      dataIndex: "table",
      key: "table",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Service",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key: "orders",
    },
  ];

  return (
    <Layout active="history">
      <div className="history-main">
        <Typography.Title level={2}>User Order's History</Typography.Title>

        <Table
          loading={isOrder === null ? true : false}
          dataSource={
            orders &&
            orders.length > 0 &&
            orders.map((order) => ({
              key: order._id,
              created: moment(order.createdAt).format("D MMM YYYY"),
              name: order.admin.name,
              status: order.status,
              table: order.table?.name,
              amount: order.amount,
              rate: <RateVenue admin={order.admin} orderId={order._id} />,
              orders: (
                <ProductsModal products={order.products} orderId={order._id} />
              ),
            }))
          }
          columns={columns}
          style={{ margin: "30px 0" }}
          pagination={{
            pageSizeOptions: ["10", "20", "40", "70"],
            showSizeChanger: true,
          }}
          scroll={{ x: 600 }}
          sticky
        />
        {/* <Row gutter={[30, 30]}>
          {orders && orders.length > 0 ? (
            orders.map((order, i) => (
              <Col xs={24} md={12} xl={8} key={order._id}>
                <div className="history-card">
                  <div>
                    <Typography.Title level={4}>Venue</Typography.Title>
                    <p>Restaurant : {order.admin.name}</p>
                    <p>Table : {order.table.name}</p>
                    <p>Amount : ${order.amount}</p>
                    <RateVenue />
                  </div>
                  <Divider />
                  <div>
                    <Typography.Title level={4}>Order Details</Typography.Title>
                    {order.products.map((product, i) => (
                      <div key={product._id}>
                        <p>Name : {product.product.name}</p>
                        <p>Quantity : {product.quantity}</p>

                        <p>Date : 26-04-2021 10:37 PM</p>
                        <RateOrder />
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "250px",
              }}
            >
              <Empty description="No Order History" />
            </div>
          )}
        </Row> */}
      </div>
    </Layout>
  );
};

export default OrderHistory;
