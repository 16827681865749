import axios from "axios";

import { url } from "../../constants";
import * as promotionTypes from "../types/promotionTypes";
const userId = localStorage.getItem("userId");

export const getFreeProductPromotion = (city) => {
  console.log();
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/promotions/get-free-product-promotion`,
        {
          city,
        }
      );
      // console.log("free", res.data);
      if (res) {
        // console.log(res);
        dispatch({
          type: promotionTypes.GET_FREE_PROMO,
          payload: res.data.promotions,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getMyPromotions = (city) => {
  console.log();
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/get-all-promotions-user`, {
        userId,
        city,
      });
      // console.log("myPromotions", res.data);
      if (res) {
        // console.log(res);
        dispatch({
          type: promotionTypes.GET_MY_PROMOTIONS,
          payload: res.data.user.promotions,
        });
        // dispatch(getFreeProductPromotion());
        // dispatch(getSingleProductPromotion());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOrderHistory = () => {
  console.log();
  return async (dispatch) => {
    try {
      await axios.post(`${url}/user/get-order-history`, {
        userId,
      });
      // console.log("history", res.data);
      //   if (res) {
      //     // console.log(res);
      //     dispatch({
      //       type: generalTypes.GET_ALL_CITIES,
      //       payload: res.data.country,
      //     });
      //   }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSingleProductPromotion = (city) => {
  console.log(city);
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/get-all-promotions`, { city });
      // console.log("single product", res.data);
      if (res) {
        // console.log(res);
        // dispatch(getMyPromotions());
        // dispatch(getFreeProductPromotion());
        dispatch({
          type: promotionTypes.GET_PRODUCT_PROMO,
          payload: res.data.promotions,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
