import axios from "axios";

import { url, headers } from "../../constants";
import * as dashboardTypes from "../types/dashboardTypes";
import swal from "sweetalert";
const userId = localStorage.getItem("userId");
const workerId = localStorage.getItem("workerId");
const adminId = localStorage.getItem("selectedAdminId");
const tableId = localStorage.getItem("selectedTableId");

export const getAdminDetails = (id) => {
  return async (dispatch) => {
    try {
      var res;
      if (id) {
        res = await axios.get(`${url}/admin/get-admin-id/${id}`, {
          headers: headers,
        });
      } else {
        res = await axios.get(`${url}/admin/get-admin-id/${adminId}`, {
          headers: headers,
        });
      }
      if (res) {
        return res.data.admin;
        // dispatch({
        //   type: dashboardTypes.GET_CATEGORIES,
        //   payload: res.data.categories,
        // });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTable = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${url}/admin/get-single-table/${id}`, {
        headers: headers,
      });
      if (res) {
        return res.data.table;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCategories = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${url}/user/get-admin-categories/${adminId}`,
        {
          headers: headers,
        }
      );
      // console.log(res);
      if (res) {
        dispatch({
          type: dashboardTypes.GET_CATEGORIES,
          payload: res.data.categories,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTableOrders = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/admin/user-orders`,
        {
          tableId,
          userId,
        },
        {
          headers: headers,
        }
      );
      // console.log(res);
      if (res) {
        dispatch({
          type: dashboardTypes.GET_ACTIVE_ORDERS,
          payload: res.data.orders,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getWorkerOrders = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/admin/worker-orders`,
        {
          workerId,
          tableId,
        },
        {
          headers: headers,
        }
      );
      if (res) {
        dispatch({
          type: dashboardTypes.GET_ACTIVE_ORDERS,
          payload: res.data.orders,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const callWaiter = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/call-waiter`,
        {
          table: tableId,
          adminId,
        },
        {
          headers: headers,
        }
      );
      console.log(res);
      if (res) {
        swal({
          icon: "success",
          title: "Waiter Called!",
        });
      }
    } catch (err) {
      console.log(err);
      swal({
        icon: "error",
        title: "server error",
      });
    }
  };
};
export const getProductsByCategory = (category) => {
  console.log(category);
  // const payload2 = {
  //   ...payload,
  //   adminId,
  // };
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/get-products-by-category`,
        {
          category,
          adminId,
        },
        {
          headers: headers,
        }
      );
      // console.log(res);
      if (res) {
        return res.data.products;
      }
    } catch (err) {
      console.log(err);
      swal({
        icon: "error",
        title: "server error",
      });
    }
  };
};
export const getProductById = (id) => {
  // console.log(id);
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${url}/user/get-product/${id}`,

        {
          headers: headers,
        }
      );
      // console.log(res);
      if (res) {
        dispatch({
          type: dashboardTypes.GET_PRODUCT,
          payload: res.data.product,
        });
        return [res.data.product.logo, ...res.data.product.logos];
      }
    } catch (err) {
      console.log(err);
      swal({
        icon: "error",
        title: "server error",
      });
    }
  };
};

export const addToCart = (payload) => {
  return {
    type: dashboardTypes.ADD_TO_CART,
    payload: payload,
  };
};
export const removeFromCart = (payload) => {
  console.log(payload);
  return {
    type: dashboardTypes.REMOVE,
    payload: payload,
  };
};

export const addQuantity = (id, value) => {
  console.log(id, value);
  return {
    type: dashboardTypes.ADD_QUANTITY,
    payload: { id, value },
  };
};

export const subtractQuantity = (id, value) => {
  return {
    type: dashboardTypes.SUBTRACT_QUANTITY,
    payload: { id, value },
  };
};

export const PlaceOrder = (cart, type, history) => {
  console.log("244", cart);
  return async (dispatch) => {
    try {
      const tableID = localStorage.getItem("selectedTableId");
      if (tableID) {
        const role = localStorage.getItem("role");
        // const workerName = localStorage.getItem("workerName");
        const workerId = localStorage.getItem("workerId");
        const userName = localStorage.getItem("userName");
        const userID = localStorage.getItem("userId");

        const workerPayload = {
          user: userID,
          userName: `${userName} Guest`,
          admin: adminId,
          workerId,
          table: tableID,
          products: cart,
          discountPercentage: "",
        };
        const guestPayload = {
          user: userID,
          userName: `${userName} Guest`,
          admin: adminId,
          table: tableID,
          products: cart,
          discountPercentage: "",
        };
        const payloadUser = {
          user: userID,
          admin: adminId,
          table: tableID,
          products: cart,
          discountPercentage: "",
        };

        console.log(
          role === "USER"
            ? payloadUser
            : role === "GUEST"
            ? guestPayload
            : workerPayload
        );

        const res = await axios.post(
          `${url}/user/post-order`,
          role === "USER"
            ? payloadUser
            : role === "GUEST"
            ? guestPayload
            : workerPayload,
          {
            headers: headers,
          }
        );
        if (res) {
          console.log(res.data);
          type !== "single" &&
            dispatch({
              type: dashboardTypes.EMPTY_CART,
            });
          swal("Order Placed!", "", "success").then(() => {
            type === "single" ? history.goBack() : history.push("/user");
          });
        } else {
          console.log("else run");
        }
      } else {
        swal("", "Please Select a Table First", "warning");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getUserPromotions = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/get-user-active-promotion`,
        {
          adminId,
          userId,
        },
        {
          headers: headers,
        }
      );
      console.log(res.data);
      if (res) {
        dispatch({
          type: dashboardTypes.USER_PROMOTIONS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const shareOrderToTable = (payload) => {
  console.log("347", payload);
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/share-order-to-table`,
        payload,
        {
          headers: headers,
        }
      );
      if (res) {
        swal(res.data.message, "", "success");
        dispatch(getUserShare());
      }
    } catch (err) {
      swal("", err?.response?.data?.message || "Server Error", "error");
      console.log(err);
    }
  };
};

export const acceptOrDeclineShare = (payload) => {
  console.log(payload);
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/accept-or-decline-share`,
        payload,
        {
          headers: headers,
        }
      );
      console.log(res.data);
      if (res) {
        if (payload.type === "accepted") {
          swal(res.data.message, "", "success");
        } else {
          swal(res.data.message, "", "error");
        }
      }
    } catch (err) {
      swal("", err?.response?.data?.message || "Server Error", "error");
      console.log(err);
    }
  };
};
export const getUserShare = (payload) => {
  console.log(payload);
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${url}/user/get-user-share`,

        {
          headers: headers,
        }
      );
      // console.log(res.data);
      if (res) {
        console.log("406", res.data);
        dispatch({
          type: dashboardTypes.GET_SHARED_ORDER,
          payload: res.data.shared_order,
        });
      }
    } catch (err) {
      swal("", err?.response?.data?.message || "Server Error", "error");
      console.log(err);
    }
  };
};
export const cancelSharedOrder = (id) => {
  console.log(id);
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${url}/user/delete-user-share/${id}`,

        {
          headers: headers,
        }
      );
      // console.log(res.data);
      if (res) {
        dispatch(getUserShare());
        swal("", res.data.message, "success");
      }
    } catch (err) {
      swal("", err?.response?.data?.message || "Server Error", "error");
      console.log(err);
    }
  };
};
export const isTableSelected = (selected) => async (dispatch) => {
  dispatch({
    type: dashboardTypes.IS_SELECTED,
    payload: selected,
  });
};
