import { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import swal from 'sweetalert'


import logo from "../assets/qrlogo.png";
import { verifyEmail } from "../redux";

const SignIn = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(verifyEmail(values.code, history));
    setLoading(false);
  };

  // if (localStorage.hasOwnProperty("adminEmail")) {
  //   return <Redirect to="/" />;
  // } else {
  return (
    <div className="signin">
      <Link to="/">
        <img src={logo} alt="logo" width="auto" height="100px" />
      </Link>
      <Form
        name="login"
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <h2>Code Verification</h2>
        <h4>Enter Verification code from Email</h4>
        <Form.Item name="code">
          <Input
            autoComplete="off"
            placeholder="Enter Code..."
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Verify
          </Button>
        </Form.Item>

        <div className="bottom-links">
          <Link to="/user" className="login-forgot">
            Countinue without Verification
          </Link>
        </div>
      </Form>
    </div>
  );
  // }
};

export default SignIn;
