import axios from "axios";

import { url } from "../../constants";
import * as authTypes from "../types/authTypes";
import swal from "sweetalert";

export const userSignup = (payload, history, adminId, tableId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/signup`, payload);
      if (res) {
        console.log(res.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.userId);
        // localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("role", "USER");
        localStorage.setItem("selectedAdminId", adminId);
        localStorage.setItem("mainAdminId", adminId);
        localStorage.setItem("selectedTableId", tableId);
        swal("", "SignUp Successfull", "success");
        history.replace("/code-verify");
        // dispatch({ type: authTypes.LOGIN });
      } else {
      }
    } catch (err) {
      swal("", err?.response?.data?.message || "Server Eror", "error");
      console.log(err);
    }
  };
};

export const userLogin = (payload, history, adminId, tableId) => {
  console.log("payload");
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/login`, payload);
      if (res) {
        console.log(res.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.userId);
        localStorage.setItem("role", "USER");
        localStorage.setItem("selectedAdminId", adminId);
        localStorage.setItem("mainAdminId", adminId);
        localStorage.setItem("selectedTableId", tableId);
        swal("", "Logged In Successfully", "success");
        history.replace("/user");
        window.location.reload();
        dispatch({ type: authTypes.LOGIN });
      }
    } catch (err) {
      swal("", "Wrong Email or Password", "error");
      console.log(err);
    }
  };
};

export const userGuestLogin = (history, adminId, tableId, name) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/guest-signup`, { name });
      if (res) {
        console.log(res.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.doc._id);
        localStorage.setItem("userName", res.data.doc.name);
        localStorage.setItem("role", "GUEST");
        localStorage.setItem("selectedAdminId", adminId);
        localStorage.setItem("mainAdminId", adminId);
        localStorage.setItem("selectedTableId", tableId);
        swal("", "Guest Logged In Successfully", "success");
        history.replace("/user");
        window.location.reload();
      }
      // dispatch({ type: authTypes.LOGIN });
    } catch (err) {
      console.log(err);
    }
  };
};
export const directGuestLogin = (history, adminId, tableId, id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${url}/user/direct-guest-login/${id}`);
      if (res) {
        console.log(res.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.doc._id);
        localStorage.setItem("userName", res.data.doc.name);
        localStorage.setItem("role", "GUEST");
        localStorage.setItem("selectedAdminId", adminId);
        localStorage.setItem("mainAdminId", adminId);
        localStorage.setItem("selectedTableId", tableId);
        swal("", "Guest Logged In Successfully", "success");
        history.replace("/user");
        window.location.reload();
      }
      // dispatch({ type: authTypes.LOGIN });
    } catch (err) {
      console.log(err);
    }
  };
};

export const verifyEmail = (code, history) => {
  return async (dispatch) => {
    try {
      const userId = localStorage.getItem("userId");
      let payload = {
        id: userId,
        code,
      };
      const res = await axios.post(`${url}/user/verify-Email`, payload);
      console.log(res.data);
      if (res.data.emailVerified) {
        swal("", "Email Verified", "success");
        history.replace("/user");
        window.location.reload();
        dispatch({ type: authTypes.LOGIN });
      } else {
        swal("Code Doesnot Matched!", "Email not Verified", "error");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const forgetPassword = (payload, history) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/forget-password`, payload);
    if (
      res &&
      res.data.message === "Recovery Code has been sent to your Email"
    ) {
      console.log(res);
      swal("", res.data.message, "success");
      localStorage.setItem("userType", payload.userType);
      localStorage.setItem("email", payload.email);
      history.push("/verify-code");
    } else {
      swal("", res.data.message, "info");
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};

export const verifyCode = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/verify-code`, payload);
    if (res && res.data.message === "Code Verified") {
      console.log(res);
      swal("", `${res.data.message},Now you can set new password`, "success");
      localStorage.setItem("recoveryToken", res.data.recoveryToken);

      return { isVerified: true };
    } else {
      swal("", `${res.data.message}`, "error");
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};
export const changePassword = (payload, history) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/change-password-forget`, payload);
    if (res) {
      console.log(res);
      swal("", `${res.data.message}`, "success").then(() => {
        history.replace("/");
        localStorage.removeItem("recoveryToken");
        localStorage.removeItem("email");
        localStorage.removeItem("userType");
      });

      return { isVerified: true };
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};
