import { Typography, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyPromotions from "../components/Promotions/MyPromotions";
import DashboardLayout from "../layout/DashboardLayout";
import {
  getAllCitiesbyCountry,
  getAllCountries,
  getFreeProductPromotion,
  getMyPromotions,
  getSingleProductPromotion,
  getUserInfo,
} from "../redux";

const Promotions = () => {
  const { Title } = Typography;
  const { Option } = Select;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.generalReducer.allCountries);
  const cities = useSelector((state) => state.generalReducer.allCities);
  const user = useSelector((state) => state.generalReducer.userInfo);

  const myPromotions = useSelector(
    (state) => state.promotionReducer.myPromotions
  );
  const freePromotions = useSelector(
    (state) => state.promotionReducer.freePromotions
  );
  const productPromotions = useSelector(
    (state) => state.promotionReducer.productPromotions
  );

  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");

  // console.log(user);

  useEffect(async () => {
    const user = await dispatch(getUserInfo());
    setUserCity(user && user.city);
    setUserCountry(user && user.country);
    dispatch(getAllCountries());
    dispatch(getMyPromotions(user && user.city));
    dispatch(getSingleProductPromotion(user && user.city));
  }, []);

  const OnCountryChange = (value) => {
    // console.log(value);
    setUserCountry(value);
    dispatch(getAllCitiesbyCountry(value));
  };

  const OnCityChange = (value) => {
    // console.log(value);
    dispatch(getFreeProductPromotion(user.city));
    dispatch(getMyPromotions(user.city));
    dispatch(getSingleProductPromotion(user.city));
  };

  const onTabChange = (key) => {
    // if (key === "all") {
    //   dispatch(getSingleProductPromotion(user && user.city));
    //   console.log("all run");
    // } else if (key === "free") {
    //   dispatch(getFreeProductPromotion(user && user.city));
    //   console.log("free run");
    // } else {
    //   // dispatch(getMyPromotions(user && user.city));
    //   console.log("my run");
    // }
  };

  return (
    <DashboardLayout active="promotions">
      <div className="promotions-container">
        <Title level={3}>Promotions</Title>
        <div className="header">
          <Title level={4}>Country</Title>
          <Select
            size="large"
            showSearch
            value={userCountry}
            style={{ width: "100%" }}
            // placeholder="Select Country"
            onChange={OnCountryChange}
            allowClear
          >
            {countries &&
              countries.length > 0 &&
              countries.map((country, i) => {
                return (
                  <Option value={country} key={i}>
                    {country}
                  </Option>
                );
              })}
          </Select>
          <br />

          <Title level={4}>City</Title>
          <Select
            size="large"
            value={userCity}
            style={{ width: "100%" }}
            placeholder="Select City"
            optionFilterProp="children"
            onChange={OnCityChange}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities &&
              cities.length > 0 &&
              cities.map((city) => {
                return (
                  <Option value={city.city} key={city._id}>
                    {city.city}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="promotions">
          <Tabs
            defaultActiveKey="all"
            type="card"
            // size="large"
            // centered
            onChange={onTabChange}
            className="tab-container"
          >
            <TabPane tab="All Promotions" key="all">
              <MyPromotions Data={productPromotions && productPromotions} />
            </TabPane>
            <TabPane tab="Free Product Promotions" key="free">
              <MyPromotions Data={freePromotions && freePromotions} />
            </TabPane>
            <TabPane tab="My Promotions" key="my">
              <MyPromotions Data={myPromotions && myPromotions} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Promotions;
