import { Button, Col, Row, Spin, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";

import Layout from "../layout/DashboardLayout";
import { useEffect, useState } from "react";
import { getProductsByCategory } from "../redux";
import { useDispatch } from "react-redux";

const CategoryListing = () => {
  const { id } = useParams();
  // console.log(productId);
  const [productByCategory, setProductByCategory] = useState(null);
  //const [loading, setLoading] = useState(false);
  console.log("20", productByCategory);
  const dispatch = useDispatch();

  useEffect(async () => {
    const product = await dispatch(getProductsByCategory(id));
    setProductByCategory(product);
    // if(productByCategory && productByCategory.length > 0){

    // }
  }, []);

  return (
    <Layout>
      <div className="categoryLisitng">
        <Link to="/user">
          <Button shape="round" type="primary" icon={<ArrowLeftOutlined />}>
            Back to Home
          </Button>
        </Link>
        <Typography.Title level={2}>
          {productByCategory && productByCategory[0]?.category?.name}
        </Typography.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row
            gutter={[30, 30]}
            style={{ width: "100%" }}
            className="listing-row"
          >
            {productByCategory && productByCategory.length > 0 ? (
              productByCategory?.length > 0 &&
              productByCategory.map((product) => {
                console.log(product);
                return (
                  <Col xs={24} md={12} key={product._id}>
                    <Link to={`/user/category/item/${product._id}`}>
                      <div className="listing-card">
                        <img src={product.logo} alt="" />
                        <p>
                          {product.name}
                          <br />
                          {product.promotion.discountPercentage ? (
                            product.discountedPrice ? (
                              <div>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    marginBottom: "5px",
                                  }}
                                >
                                  TL {product.price}
                                </span>
                                <br />
                                TL{" "}
                                {Math.round(product.discountedPrice).toFixed(1)}
                              </div>
                            ) : (
                              `TL ${product.price}`
                            )
                          ) : (
                            `TL ${product.price}`
                          )}
                          <br />
                          {product?.waitTime
                            ? `Wait Time : ${product?.waitTime}`
                            : ""}
                          <br />
                          {product?.allergic?.lengh > 0
                            ? `  Allergies :{" "}
                            ${product?.allergic?.map((allergy) => {
                              return allergy;
                            })}`
                            : ""}
                        </p>
                      </div>
                    </Link>
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                  width: "100%",
                }}
              >
                <Spin size="large" />
              </div>
            )}
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default CategoryListing;
