import * as dashboardTypes from "../types/dashboardTypes";

const initialState = {
  categories: [],
  activeOrders: [],
  product: {},
  cart: [],
  userPromotions: [],
  sharedOrder: null,
  tableSelected: false,
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case dashboardTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
      break;
    case dashboardTypes.GET_ACTIVE_ORDERS:
      return {
        ...state,
        activeOrders: payload,
      };
      break;
    case dashboardTypes.GET_PRODUCT:
      return {
        ...state,
        product: payload,
      };
      break;
    case dashboardTypes.ADD_TO_CART:
      return { ...state, cart: [...state.cart, payload] };
    case dashboardTypes.REMOVE:
      console.log(payload);
      let removed = state.cart.filter((item) => {
        return item.product._id !== payload.product._id;
      });
      return { ...state, cart: removed };
    case dashboardTypes.EMPTY_CART:
      return {
        ...state,
        cart: [],
      };
      break;
    case dashboardTypes.USER_PROMOTIONS:
      return {
        ...state,
        userPromotions: payload,
      };
      break;
    case dashboardTypes.ADD_QUANTITY:
    case dashboardTypes.SUBTRACT_QUANTITY:
      let temp = [];
      state.cart.map((item) => {
        if (item.product._id === payload.id) {
          temp.push({
            ...item,
            quantity: payload.value,
          });
        } else {
          temp.push(item);
        }
      });
      // console.log(payload);
      return {
        ...state,
        cart: temp,
      };
      break;
    case dashboardTypes.GET_SHARED_ORDER:
      return {
        ...state,
        sharedOrder: payload,
      };
    case dashboardTypes.IS_SELECTED:
      return {
        ...state,
        tableSelected: payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
