import { useEffect } from "react";
import "antd/dist/antd.less";

import Routes from "./Routes";
import theme from "./theme.json";
import "./styles/style.css";
import openSocket from "socket.io-client";
import { url } from "./constants";
import swal from "sweetalert";
import { acceptOrDeclineShare } from "./redux";
import { useDispatch } from "react-redux";

const App = () => {
  const mainAdminId = localStorage.getItem("mainAdminId");
  const tableId = localStorage.getItem("selectedTableId");
  const userId = localStorage.getItem("userId");

  const dispatch = useDispatch();

  useEffect(() => {
    const socket = openSocket(url);

    socket.on(mainAdminId + tableId, (data) => {
      console.log(data.details.byUser._id);
      if (userId !== data.details.byUser._id) {
        swal({
          title: "Order Share Alert!",
          text: `${data.details.byUser.name} wants to share order with you.`,
          // text: `${data.details.byUser.name} wants to share ${data.details.product.name} with you.`,
          icon: "info",
          buttons: {
            Cancel: "Decline",
            Accept: "Accept Share",
          },
        }).then((value) => {
          if (value === "Accept") {
            const payload = {
              user: userId,
              shareId: data.details.order._id,
              type: "accepted",
            };
            dispatch(acceptOrDeclineShare(payload));
          } else if (value === "Cancel") {
            const payload = {
              user: userId,
              shareId: data.details.order._id,
              type: "declined",
            };
            dispatch(acceptOrDeclineShare(payload));
          }
        });
      }
    });

    socket.on(userId, (data) => {
      console.log(data);
      if (data.action === "order declined") {
        swal({
          title: "Order Share Declined!",
          text: `Your Order Share Request has been Declined by ${data.details.byUser.name}`,
          // text: `Your Share Request for ${data.details.product.name} has been Declined by ${data.details.byUser.name}`,
          icon: "error",
          buttons: {
            Proceed: "Proceed to Place Order",
          },
        });
        // .then((value) => {
        //   if (value === 'Proceed') {
        //     const payload = {
        //       user: userId,
        //       shareId: data.details.order._id,
        //       type: 'accepted',
        //     };
        //     dispatch(acceptOrDeclineShare(payload));
        //   }
        // });
      } else {
        swal({
          title: "Order Share Accepted!",
          // text: `Your Share Request for ${data.details.product.name} has been Accepted by ${data.details.byUser.name}`,
          text: `Your Order Share Request has been Accepted by ${data.details.byUser.name}`,
          icon: "success",
          buttons: {
            Proceed: "Proceed to Place Order",
          },
        });
      }
    });

    Object.keys(theme).forEach((key) => {
      document.body.style.setProperty(`--${key}`, theme[key]);
    });
  }, []);

  return <Routes />;
};

export default App;

// version 1
