import { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import swal from 'sweetalert'


import logo from "../assets/qrlogo.png";
import { forgetPassword } from "../redux";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    const payload = {
      userType: "user",
      email,
    };
    await dispatch(forgetPassword(payload, history));
    setLoading(false);
  };

  // if (localStorage.hasOwnProperty("adminEmail")) {
  //   return <Redirect to="/" />;
  // } else {
  return (
    <div className="signin">
      <Link to="/">
        <img src={logo} alt="logo" width="auto" height="100px" />
      </Link>
      <Form
        name="login"
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <h2>Forget Password</h2>
        <Form.Item name="email" label="Email">
          <Input
            autoComplete="off"
            placeholder="Enter Email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // onClick={Login}
            loading={loading}
          >
            Send Code
          </Button>
        </Form.Item>

        <div className="bottom-links">
          <Link to="/user-login/1/1" className="login-forgot">
            Back to Sign In
          </Link>
        </div>
      </Form>
    </div>
  );
  // }
};

export default SignIn;
