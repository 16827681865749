import {
  Input,
  Typography,
  Form,
  Select,
  Checkbox,
  Button,
  Divider,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import Layout from "../layout/DashboardLayout";
import {
  getAllCitiesbyCountry,
  getAllCountries,
  getUserInfo,
  updatePassword,
  updateProfile,
  verifyEmail,
} from "../redux";

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useSelector((state) => state.generalReducer.userInfo);
  const countries = useSelector((state) => state.generalReducer.allCountries);
  const cities = useSelector((state) => state.generalReducer.allCities);

  const [getMails, setGetMails] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(userInfo);
  const onFinish = (values) => {
    console.log(values);
    if (
      values.name !== undefined &&
      values.surname !== undefined &&
      values.mobile !== undefined &&
      values.city !== undefined &&
      values.country !== undefined
    ) {
      const payload = {
        name: values.name,
        surname: values.surname,
        city: values.city,
        country: values.country,
        mobile: values.mobile,
        getMails: getMails,
      };
      dispatch(updateProfile(payload));
    } else {
      swal({
        title: "one of the fields are missing",
        icon: "error",
      });
    }
  };
  const onFinishPassword = (values) => {
    if (
      values.currentPass !== undefined &&
      values.newPass !== undefined &&
      values.confirmPass !== undefined
    ) {
      if (values.newPass === values.confirmPass) {
        const payload = {
          oldPassword: values.currentPass,
          newPassword: values.newPass,
          confirmPassword: values.confirmPass,
        };
        dispatch(updatePassword(payload));
      } else {
        swal({
          icon: "error",
          title: "Passwords Doesn't match",
        });
      }
    } else {
      swal({
        icon: "error",
        title: "fields are missing",
      });
    }
  };

  const onFinishCodeVerify = async (values) => {
    setLoading(true);
    await dispatch(verifyEmail(values.code, history));
    setLoading(false);
  };

  // const onChange = (value) => {
  //   console.log(`selected ${value}`);
  // };
  const CheckboxonChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setGetMails(e.target.checked);
    console.log(getMails);
  };

  const onCountryChange = (value) => {
    console.log(value);
    dispatch(getAllCitiesbyCountry(value));
  };

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAllCountries(userInfo.country));
  }, []);

  return (
    <Layout active="settings">
      <Typography.Title level={3}>Account Settings</Typography.Title>
      <Form
        layout="vertical"
        onFinish={onFinish}
        fields={[
          {
            name: ["name"],
            value: userInfo.name,
          },
          {
            name: ["surname"],
            value: userInfo.surname,
          },
          {
            name: ["mobile"],
            value: userInfo.mobile,
          },
          {
            name: ["country"],
            value: userInfo.country,
          },
          {
            name: ["city"],
            value: userInfo.city,
          },
        ]}
      >
        <Form.Item label="First Name" name="name">
          <Input
          // placeholder={userInfo.name}
          // value={name === "" ? userInfo.name : name}
          // onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Last Name" name="surname">
          <Input
          // placeholder={userInfo.surname}
          // value={surName === "" ? userInfo.surname : surName}
          // onChange={(e) => setSurName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Phone Number" name="mobile">
          <Input
          // placeholder={userInfo.mobile}
          // value={mobileNumber === "" ? userInfo.mobile : mobileNumber}
          // onChange={(e) => setMobileNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Select Country" name="country">
          <Select
            size="large"
            showSearch
            style={{ width: "100%" }}
            // placeholder={userInfo.country}
            optionFilterProp="children"
            onChange={onCountryChange}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries &&
              countries.map((country, i) => {
                return (
                  <Select.Option key={i} value={country}>
                    {country}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Select City/District" name="city">
          <Select
            size="large"
            showSearch
            style={{ width: "100%" }}
            // placeholder={userInfo.city}
            optionFilterProp="children"
            // onChange={onCountryChange}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities &&
              cities.length > 0 &&
              cities.map((city) => {
                return (
                  <Select.Option key={city._id} value={city.city}>
                    {city.city}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={CheckboxonChange} checked={getMails}>
            I don't want to get promotion mails.
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            SAVE INFO
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Typography.Title level={3}>Change Password</Typography.Title>
      <Form
        name="change password"
        layout="vertical"
        onFinish={onFinishPassword}
        // fields={[
        //   {
        //     name: ['currentPass'],
        //     value: userInfo.name,
        //   },
        // ]}
      >
        <Form.Item label="Current Password" name="currentPass">
          <Input.Password />
        </Form.Item>
        <Form.Item label="New Password" name="newPass">
          <Input.Password />
        </Form.Item>
        <Form.Item label="Confirm Password" name="confirmPass">
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            CHANGE PASSWORD
          </Button>
        </Form.Item>
      </Form>

      {!userInfo.emailVerified && (
        <div style={{ marginTop: "50px" }}>
          <Typography.Title level={3}>
            Your Email is Not Verifed
          </Typography.Title>
          <Typography.Title level={5}>
            Enter Code from mail to Verify
          </Typography.Title>
          <Form layout="vertical" onFinish={onFinishCodeVerify}>
            <Form.Item name="code">
              <Input required placeholder="Enter Code..." />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Verify
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Layout>
  );
};

export default Settings;
