import axios from "axios";

import { url, headers } from "../../constants";
import * as orderHistoryTypes from "../types/orderHistoryTypes";
import swal from "sweetalert";
const userId = localStorage.getItem("userId");
const admin = localStorage.getItem("mainAdminId");

export const getOrdersHistory = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/get-user-order-history`,
        {
          user: userId,
          admin,
        },
        {
          headers: headers,
        }
      );
      console.log(res);
      if (res) {
        dispatch({
          type: orderHistoryTypes.GET_ORDER_HISTORY,
          payload: res.data.orders,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateProfile = (payload) => {
  const payload2 = { ...payload, userId };
  console.log(payload2);

  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/update-profile`, payload2, {
        headers: headers,
      });
      console.log(res);
      if (res) {
        swal({
          icon: "success",
          title: "profile updated successfully",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const updatePassword = (payload) => {
  const payload2 = { ...payload, userId };
  console.log(payload2);

  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/user/change-password`, payload2, {
        headers: headers,
      });
      console.log(res);
      if (res) {
        swal({
          icon: "success",
          title: "password changed successfully",
        });
      }
    } catch (err) {
      console.log(err);
      swal({
        title: "error updating password please try again later",
        icon: "error",
      });
    }
  };
};
