import { useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";

import { addQuantity, subtractQuantity } from "../redux";

const Counter = ({ increment, defaultQuantity, id, isCart }) => {
  // console.log(defaultQuantity);
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultQuantity ? defaultQuantity : 1);
  const Increment = () => {
    setValue(value + 1);
    increment(value + 1);
    isCart && dispatch(addQuantity(id, value + 1));
    // console.log(defaultQuantity + 1);
  };
  const Decrement = () => {
    // if (value === 1) {
    //   setValue(1);
    //   increment(value);
    //   isCart && dispatch(subtractQuantity(id, 1));
    // } else {
    setValue(value - 1);
    increment(value - 1);
    isCart && dispatch(subtractQuantity(id, value - 1));
    // }
  };

  return (
    <div className="counter-comp">
      <Button
        shape="circle"
        disabled={value === 1 ? true : false}
        onClick={() => Decrement()}
        icon={<MinusOutlined />}
      />
      <span>{value}</span>

      <Button
        shape="circle"
        onClick={() => Increment()}
        icon={<PlusOutlined />}
      />
    </div>
  );
};

export default Counter;
