import * as historyTypes from "../types/orderHistoryTypes";

const initialState = {
  orderHistory: [],
};

const promotionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case historyTypes.GET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: payload,
      };

    default:
      return state;
  }
};

export default promotionReducer;
