import React, { useState } from "react";
import { Modal, Button, Rate, Input, Typography } from "antd";
import { useDispatch } from "react-redux";
import { rateAdmin } from "../../redux";
import swal from "sweetalert";

const RateVenue = ({ admin, orderId }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [shouldRate, setShouldRate] = useState(true);
  const [shouldComment, setShouldComment] = useState(true);

  const [loading, setLoading] = useState(false);

  const [rate, setRate] = useState(2.5);
  const [comment, setComment] = useState("");
 // const userId = localStorage.getItem("userId");

  const showModal = () => {
    setIsModalVisible(true);
    checkRating();
    console.log(admin);
    console.log(orderId);
  };

  console.log(shouldComment, shouldRate);

  const checkRating = () => {
    admin &&
      admin.rating.length > 0 &&
      admin.rating.map((rate) => {
        if (rate.order === orderId) {
          setShouldRate(false);
          setRate(rate.rate);
        }
      });
    admin &&
      admin.comments.length > 0 &&
      admin.comments.map((comment) => {
        if (comment.order === orderId) {
          setShouldComment(false);
          setComment(comment.comment);
        }
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onRateChange = (rating) => {
    setRate(rating);
  };

  const submitRating = async () => {
    setLoading(true);
    if (comment !== "") {
      await dispatch(rateAdmin(rate, comment, orderId));
      setLoading(false);
      handleCancel();
    } else {
      setLoading(false);
      swal("", "Comment is required", "warning");
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ height: "32px", padding: "4px 10px" }}
      >
        Rate Venue
      </Button>
      <Modal
        title="Rate Restaurant Service"
        visible={isModalVisible}
        onOk={handleOk}
        width={"60%"}
        footer={
          <Button
            disabled={!(shouldComment && shouldRate) ? true : false}
            type="primary"
            loading={loading}
            onClick={submitRating}
          >
            Submit
          </Button>
        }
        onCancel={handleCancel}
      >
        {!shouldRate && !shouldComment && (
          <Typography.Title
            level={4}
            style={{ textAlign: "center", color: "red" }}
          >
            You Have Already Rated This Venue
          </Typography.Title>
        )}
        <p>Give Rating</p>
        <Rate
          disabled={shouldRate ? false : true}
          allowHalf
          onChange={onRateChange}
          defaultValue={rate}
        />{" "}
        {!shouldRate && "Already Rated"}
        <p style={{ marginTop: "20px" }}>Enter your Review/Comments</p>
        <Input.TextArea
          rows={5}
          value={comment}
          disabled={shouldComment ? false : true}
          style={{ resize: "none", marginBottom: "20px" }}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default RateVenue;
