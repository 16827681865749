import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
// import swal from 'sweetalert'

import logo from "../assets/qrlogo.png";
import {
  userLogin,
  userGuestLogin,
  getTable,
  directGuestLogin,
  getAdminTableOrders,
} from "../redux";
import swal from "sweetalert";
import Swal from "sweetalert2";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [guestLoading, setGuestLoading] = useState(false);

  const [admin, setAdmin] = useState(null);

  const { adminId, tableId } = useParams();
  const customerList = useSelector((state) => state.workerReducer.tableOrders);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(async () => {
    const adminData = await dispatch(getTable(tableId));
    await dispatch(getAdminTableOrders(tableId));
    console.log(adminData);
    setAdmin(adminData);
  }, []);

  console.log(customerList);

  const onFinish = async (values) => {
    setLoading(true);
    // await dispatch(userLogin(values, history, adminId, tableId));
    if (admin && admin.admin.active && admin.active) {
      if (email && password) {
        await dispatch(userLogin(values, history, adminId, tableId));
      } else {
        swal("", "Email or Password is missing", "error");
      }
    } else {
      if (admin && admin.active && !admin.admin.active) {
        swal("Can't Login", "This Admin is Inactive", "error");
      } else if (admin && !admin.active && admin.admin.active) {
        swal("Can't Login", "This Table is Inactive", "error");
      } else if (admin && !admin.active && !admin.admin.active) {
        swal("Can't Login", "Sorry! The Admin & Table is Inactive", "error");
      } else if (!(adminId && tableId)) {
        if (email && password) {
          await dispatch(userLogin(values, history, undefined, undefined));
        }
      }
    }
    setLoading(false);
  };

  const guestLogin = () => {
    setGuestLoading(true);
    if (admin && admin.admin.active && admin.active) {
      Swal.fire({
        title: `Login By`,
        input: "select",
        inputOptions: customerList?.map((customer) => {
          return customer?.user?.name;
        }),
        inputPlaceholder: "Select Customer",
        showCancelButton: true,
        showCloseButton: true,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: "Log In",
        denyButtonText: "New User",

        inputValidator: async (value) => {
          return new Promise((resolve) => {
            if (value !== "") {
              console.log("value", customerList[value]?.user._id);
              dispatch(
                directGuestLogin(
                  history,
                  adminId,
                  tableId,
                  customerList[value]?.user._id
                )
              );
              // setSelectedUsername(customerList[value]?.user?.name);
              resolve();
            } else {
              // dispatch(PlaceOrder([payload], "single", history));
              // setOrderLoading(false);
              // console.log(value);
              Swal.fire(`Please Select User `, "", "info");
              resolve();
            }
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("result", result);
          // await dispatch(userGuestLogin(history, adminId, tableId, value))
          // await dispatch(PlaceOrder([payload], "single", history));
        } else if (result.isDenied) {
          Swal.fire({
            title: "New User Name",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Continue",
            showLoaderOnConfirm: true,

            inputValidator: async (newValue) => {
              return new Promise(async (resolve) => {
                if (newValue !== "") {
                  console.log("newValue", newValue);
                  dispatch(userGuestLogin(history, adminId, tableId, newValue));
                  resolve();
                } else {
                  Swal.fire(`Please Specify User Name!`, "", "info");
                  resolve();
                }
              });
            },
          }).then((result2) => {
            console.log(result2);
          });
        }
      });

      // swal("Enter Your Name", {
      //   content: "input",
      // }).then(async (value) => {
      //   if (value === "") {
      //     swal("", "Name is Required", "error");
      //   } else {
      //     dispatch(userGuestLogin(history, adminId, tableId, value));
      //   }
      // });
    } else {
      if (admin && admin.active && !admin.admin.active) {
        swal("Can't Login", "This Admin is Inactive", "error");
        // setGuestLoading(false);
      } else if (admin && !admin.active && admin.admin.active) {
        swal("Can't Login", "This Table is Inactive", "error");
        // setGuestLoading(false);
      } else if (admin && !admin.active && !admin.admin.active) {
        swal("Can't Login", "Sorry! The Admin & Table is Inactive", "error");
        // setGuestLoading(false);
      }
    }
    setGuestLoading(false);
  };

  // if (localStorage.hasOwnProperty("userId")) {
  //   return <Redirect to="/user" />;
  // } else {
  return (
    <div className="signin">
      <Link to="/">
        <img src={logo} alt="logo" width="auto" height="100px" />
        {/* <p>QRTREAT</p> */}
      </Link>
      <Form
        name="login"
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <h2>User Login</h2>
        <Form.Item name="email" label="Email">
          <Input
            autoComplete="off"
            placeholder="Enter Email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="password" label="Password">
          <Input.Password
            type="password"
            placeholder="Password..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            className="login-btn"
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Login
          </Button>
        </Form.Item>

        {admin && admin.admin.orderStatus === "no register" && (
          <Button
            onClick={guestLogin}
            loading={guestLoading}
            className="guest-btn"
          >
            Continue as Guest
          </Button>
        )}

        <div className="bottom-links">
          <p>
            <Link
              to={`/user-register/${adminId}/${tableId}`}
              className="login-forgot"
            >
              New User? Register
            </Link>
          </p>
          <p>
            <Link to="/forget/user" className="login-forgot">
              Forgot password?
            </Link>
          </p>
        </div>
      </Form>
    </div>
  );
};
// };

export default SignIn;
