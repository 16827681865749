import { combineReducers } from 'redux';

import authReducer from './reducers/authReducer';
import generalReducer from './reducers/generalReducer';
import promotionReducer from './reducers/promotionReducer';
import historyReducer from './reducers/historyReducer';
import dashboardReducer from './reducers/dashboardreducer';
import workerReducer from './reducers/workerReducer';

const rootReducer = combineReducers({
  authReducer,
  generalReducer,
  promotionReducer,
  historyReducer,
  dashboardReducer,
  workerReducer,
});

export default rootReducer;
