import { Button, Typography, Collapse, Empty, Tag } from "antd";
import axios from "axios";
import swal from "sweetalert";

import Layout from "../layout/DashboardLayout";
import { DeleteOutlined } from "@ant-design/icons";
import Counter from "../components/Counter";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  removeFromCart,
  PlaceOrder,
  shareOrderToTable,
  getUserShare,
  cancelSharedOrder,
} from "../redux";
import { url } from "../constants";
import Swal from "sweetalert2";

const Cart = () => {
  const [
    ,
    //Counterquantity
    setCounterQuantity,
  ] = useState(1);

  const [canPostOrder, setCanPostOrder] = useState(null);

  // console.log(Counterquantity);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [orderLoading, setOrderLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [selectedUserName, setSelectedUsername] = useState("");

  // const [isShared, setIsShared] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const selectedAdminId = localStorage.getItem("selectedAdminId");
  const mainAdminId = localStorage.getItem("mainAdminId");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const tableId = localStorage.getItem("selectedTableId");
  const role = localStorage.getItem("role");

  const cart = useSelector((state) => state.dashboardReducer.cart);
  const isShared = useSelector((state) => state.dashboardReducer.sharedOrder);
  const customerList = useSelector((state) => state.workerReducer.tableOrders);

  console.log("95", isShared);
  useEffect(() => {
    dispatch(getUserShare());

    handleGetMyLocation();
    // console.log(`cart`, cart);
    let sum = 0;
    let quantity = 0;
    cart &&
      cart.length > 0 &&
      cart.map((value) => {
        sum +=
          Number(
            value.product.discountedPrice
              ? value.product.discountedPrice
              : value.product.price
          ) * value.quantity;
        quantity += value.quantity;
        value.extraProducts &&
          value.extraProducts.map((ex) => {
            ex.active && (sum += Number(ex.details.price));
          });
      });
    setTotalAmount(Math.round(sum.toFixed(1)));
    setTotalQuantity(quantity);
  }, [cart]);

  const { Panel } = Collapse;

  const handleGetMyLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position.coords.accuracy)
        // console.log([position.coords.latitude, position.coords.longitude])
        // console.log('handleGetAdminLocation');

        const adminId = localStorage.getItem("selectedAdminId");

        axios
          .post(`${url}/admin/admin-location`, { adminId })
          .then((res) => {
            // console.log(res.data.admin.location)
            // this.setState({ adminLocation: res.data.admin.location })

            const R = 6371e3; // metres
            const φ1 = (res.data.admin.location.lat * Math.PI) / 180; // φ, λ in radians
            const φ2 = (position.coords.latitude * Math.PI) / 180;
            const Δφ =
              ((position.coords.latitude - res.data.admin.location.lat) *
                Math.PI) /
              180;
            const Δλ =
              ((position.coords.longitude - res.data.admin.location.lng) *
                Math.PI) /
              180;

            const a =
              Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const d = R * c; // in metres

            // console.log(position.coords.accuracy + " Accuracy");
            // console.log(d + " d");
            // console.log(res.data.admin.location.lat + "a lat");
            // console.log(res.data.admin.location.lng + "a lng");

            // console.log(position.coords.latitude + " lat");
            // console.log(position.coords.longitude + " lng");

            let accurayMeasure =
              position.coords.accuracy >= 800 ? position.coords.accuracy : 800;

            setCanPostOrder(!(d >= accurayMeasure));
            // this.setState({
            //     canPostOrder: true
            // })
          })
          .catch((err) => {
            console.log(err);
          });
      },
      (e) => {
        console.log(e);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  // const returnPayload = (product) => {
  //   let extraAmount = 0;
  //   let extraCost = 0;
  //   let exclude = [];
  //   let include = [];
  //   let allergicNames = [];
  //   let extraProducts = [];
  //   product.allergic &&
  //     product.allergic.length > 0 &&
  //     product.allergic.map((allergy) => {
  //       const index = allergicTags.indexOf(allergy);
  //       if (index === -1) {
  //         allergicNames.push({
  //           allergicName: allergy,
  //           active: false,
  //         });
  //       } else {
  //         allergicNames.push({
  //           allergicName: allergy,
  //           active: true,
  //         });
  //       }
  //     });

  //   product.extraProducts &&
  //     product.extraProducts.length > 0 &&
  //     product.extraProducts.map((extra) => {
  //       extraProductTags && extraProductTags.length > 0
  //         ? extraProductTags.map((extra2) => {
  //             if (extra2.name === extra.name) {
  //               extraProducts.push({
  //                 productName: extra.name,
  //                 active: true,
  //                 details: extra,
  //               });
  //               extraAmount += Number(extra.price);
  //               extraCost += Number(extra.price);
  //             }
  //           })
  //         : extraProducts.push({
  //             productName: extra.name,
  //             active: false,
  //             details: extra,
  //           });
  //     });

  //   product.ingrediants &&
  //     product.ingrediants.length > 0 &&
  //     product.ingrediants.map((ingrediant) => {
  //       const index = ingredientTags.indexOf(ingrediant);
  //       if (index === -1) {
  //         exclude.push(ingrediant);
  //       } else {
  //         include.push(ingrediant);
  //       }
  //     });

  //   const payload = {
  //     product: product._id,
  //     quantity,
  //     allergicNames,
  //     extraAmount,
  //     extraCost,
  //     extraProducts,
  //     include,
  //     exclude,
  //   };
  //   return payload;
  // };

  const createOrder = async () => {
    let payload = [];
    cart &&
      cart.map((item) => {
        payload.push({
          ...item,
          product: item.product._id,
        });
      });
    console.log(payload);

    if (selectedAdminId === mainAdminId) {
      if (role === "WORKER") {
        !canPostOrder
          ? swal("Error", "Not in Range to Place Order", "error")
          : userId
          ? Swal.fire({
              title: `Order Will be placed By`,
              input: "select",
              inputOptions: customerList?.map((customer) => {
                return customer?.user?.name;
              }),
              inputPlaceholder: "Select Customer",
              showCancelButton: true,
              showCloseButton: true,
              showDenyButton: true,
              showConfirmButton: true,
              confirmButtonText: "Place Order",
              denyButtonText: "New User",

              inputValidator: async (value) => {
                return new Promise((resolve) => {
                  if (value !== "") {
                    // console.log(customerList[value]?.user?.name);
                    setSelectedUsername(customerList[value]?.user?.name);
                    resolve();
                  } else {
                    // dispatch(PlaceOrder([payload], "single", history));
                    // setOrderLoading(false);
                    // console.log(value);
                    Swal.fire(`Please Select User `, "", "info");
                    resolve();
                  }
                });
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                console.log(result);
                await dispatch(PlaceOrder(payload, "cart", history));
                setOrderLoading(false);
              } else if (result.isDenied) {
                Swal.fire({
                  title: "New User Name",
                  input: "text",
                  inputAttributes: {
                    autocapitalize: "off",
                  },
                  showCancelButton: true,
                  confirmButtonText: "Place Order",
                  showLoaderOnConfirm: true,

                  inputValidator: async (newValue) => {
                    return new Promise(async (resolve) => {
                      if (newValue !== "") {
                        const res = await axios.post(
                          `${url}/user/guest-signup`,
                          {
                            name: newValue,
                          }
                        );
                        if (res) {
                          localStorage.setItem("userId", res.data.doc._id);
                          localStorage.setItem("userName", res.data.doc.name);
                          dispatch(PlaceOrder(payload, "cart", history));
                          setOrderLoading(false);
                        }
                        setSelectedUsername(newValue);
                        resolve();
                      } else {
                        // dispatch(PlaceOrder([payload], "single", history));
                        // setOrderLoading(false);
                        // console.log(value);
                        Swal.fire(`Please Specify User Name!`, "", "info");
                        resolve();
                        setOrderLoading(false);
                      }
                    });
                  },
                }).then((result2) => {
                  console.log();
                });
              }
            })
          : swal("Please Specify User Name", {
              content: "input",
            }).then(async (value) => {
              if (value === "") {
                swal("", "Name is Required", "error");
              } else {
                const res = await axios.post(`${url}/user/guest-signup`, {
                  name: value,
                });
                if (res) {
                  localStorage.setItem("userId", res.data.doc._id);
                  localStorage.setItem("userName", res.data.doc.name);
                  dispatch(PlaceOrder(payload, "cart", history));
                  setOrderLoading(false);
                }
              }
            });
        // ? swal(`Order will be placed by user name ${userName}`, {
        //     buttons: {
        //       Cancel: "Cancel",
        //       New: "New User",
        //       Continue: "Continue",
        //     },
        //   }).then(async (value2) => {
        //     console.log(value2);
        //     if (value2 === "Continue") {
        //       dispatch(PlaceOrder(payload, "cart", history));
        //     } else if (value2 === "New") {
        //       swal("Please Specify User Name", {
        //         content: "input",
        //       }).then(async (newValue) => {
        //         if (newValue === "") {
        //           swal("", "Name is Required", "error");
        //         } else {
        //           await axios
        //             .post(`${url}/user/guest-signup`, {
        //               name: newValue,
        //             })
        //             .then((res) => {
        //               localStorage.setItem("userId", res.data.doc._id);
        //               localStorage.setItem("userName", res.data.doc.name);
        //               dispatch(PlaceOrder(payload, "cart", history));
        //             });
        //         }
        //       });
        //     }
        //   })
        // : swal("Please Specify User Name", {
        //     content: "input",
        //   }).then(async (value) => {
        //     if (value === "") {
        //       swal("", "Name is Required", "error");
        //     } else {
        //       const res = await axios.post(`${url}/user/guest-signup`, {
        //         name: value,
        //       });
        //       if (res) {
        //         localStorage.setItem("userId", res.data.doc._id);
        //         localStorage.setItem("userName", res.data.doc.name);
        //         dispatch(PlaceOrder(payload, "cart", history));
        //       }
        //     }
        //   });
      } else {
        setOrderLoading(true);
        await dispatch(PlaceOrder(payload, "cart", history));
        setOrderLoading(false);
        // !canPostOrder
        //   ? swal("Error", "Not in Range to Place Order", "error")
        //   : dispatch(PlaceOrder(payload, "cart", history));
      }
    } else {
      swal("Error", "You are in View Mode only", "error");
    }
  };
  const shareOrder = async () => {
    // console.log(cart);
    let payload = [];
    const payload2 = {
      products: payload,
      admin: mainAdminId,
      table: tableId,
      user: userId,
    };
    cart &&
      cart?.map((item) => {
        payload.push({
          ...item,
          product: item.product._id,
        });
      });

    // console.log(payload2);
    setShareLoading(true);
    await dispatch(shareOrderToTable(payload2));
    setShareLoading(false);

    // if (selectedAdminId === mainAdminId) {
    //   if (role === "WORKER") {
    //     !canPostOrder
    //       ? swal("Error", "Not in Range to Place Order", "error")
    //       : userId
    //       ? swal(`Order will be placed by user name ${userName}`, {
    //           buttons: {
    //             Cancel: "Cancel",
    //             New: "New User",
    //             Continue: "Continue",
    //           },
    //         }).then(async (value2) => {
    //           console.log(value2);
    //           if (value2 === "Continue") {
    //             dispatch(PlaceOrder(payload, "cart", history));
    //           } else if (value2 === "New") {
    //             swal("Please Specify User Name", {
    //               content: "input",
    //             }).then(async (newValue) => {
    //               if (newValue === "") {
    //                 swal("", "Name is Required", "error");
    //               } else {
    //                 await axios
    //                   .post(`${url}/user/guest-signup`, {
    //                     name: newValue,
    //                   })
    //                   .then((res) => {
    //                     localStorage.setItem("userId", res.data.doc._id);
    //                     localStorage.setItem("userName", res.data.doc.name);
    //                     dispatch(PlaceOrder(payload, "cart", history));
    //                   });
    //               }
    //             });
    //           }
    //         })
    //       : swal("Please Specify User Name", {
    //           content: "input",
    //         }).then(async (value) => {
    //           if (value === "") {
    //             swal("", "Name is Required", "error");
    //           } else {
    //             const res = await axios.post(`${url}/user/guest-signup`, {
    //               name: value,
    //             });
    //             if (res) {
    //               localStorage.setItem("userId", res.data.doc._id);
    //               localStorage.setItem("userName", res.data.doc.name);
    //               dispatch(PlaceOrder(payload, "cart", history));
    //             }
    //           }
    //         });
    //   } else {
    //     setOrderLoading(true);
    //     await dispatch(PlaceOrder(payload, "cart", history));
    //     setOrderLoading(false);
    //     // !canPostOrder
    //     //   ? swal("Error", "Not in Range to Place Order", "error")
    //     //   : dispatch(PlaceOrder(payload, "cart", history));
    //   }
    // } else {
    //   swal("Error", "You are in View Mode only", "error");
    // }
  };
  console.log("71", isShared);
  return (
    <Layout active="cart">
      <div className="cart-main">
        <Typography.Title level={3}>Order Cart</Typography.Title>
        <div className="cart-section">
          <div className="left">
            {cart && cart.length > 0 ? (
              cart.map((item) => {
                return (
                  <div className="cart-card" key={item.product._id}>
                    <Button
                      // type='primary'
                      shape="circle"
                      icon={<DeleteOutlined />}
                      className="delete-button"
                      onClick={() => dispatch(removeFromCart(item))}
                    />
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${item.product.logo})` }}
                    />
                    <div className="info">
                      <p>
                        <Typography.Title level={4}>
                          {item.product.name}
                        </Typography.Title>
                      </p>

                      {item?.allergicNames?.length > 0 ? (
                        <p>
                          Allergies :{" "}
                          {item?.allergicNames?.map((allergy) => {
                            return allergy.active && `${allergy.allergicName},`;
                          })}
                        </p>
                      ) : (
                        ""
                      )}
                      {item.include && item.include.length > 0 ? (
                        <p
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          Ingredients :<span>{item.include.join(",")}</span>
                        </p>
                      ) : (
                        ""
                      )}

                      <p>
                        Quantity:{" "}
                        <Counter
                          increment={setCounterQuantity}
                          defaultQuantity={item.quantity}
                          id={item.product._id}
                          isCart={true}
                        />
                      </p>
                      <p>
                        Price :{" "}
                        {item.product && item.product.discountedPrice ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: "20px",
                              }}
                            >
                              TL {item.product.price}
                            </span>
                            TL{" "}
                            {Math.round(item.product.discountedPrice).toFixed(
                              1
                            )}
                          </>
                        ) : (
                          `TL ${item.product.price}`
                        )}
                      </p>
                      {/* <p>
                        Price :{" "}
                        <span className="price">{`$
                          ${Number(item.product.price)}
                        `}</span>
                      </p> */}
                      <Collapse
                        style={{ margin: "0 10px 20px 0" }}
                        className="site-collapse-custom-collapse"
                      >
                        {item.extraProducts && item.extraProducts.length > 0 ? (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                Extra Products
                              </span>
                            }
                            // className="site-collapse-custom-panel"
                          >
                            {item.extraProducts.map((ep) => {
                              return (
                                ep.active && (
                                  <div>
                                    {/* <img
                                      src={ep.details.logo}
                                      alt="extra product"
                                      width="50%"
                                      height="100px"
                                      style={{ marginBottom: 10 }}
                                    /> */}
                                    <p>
                                      Name : <span>{ep.details.name}</span>
                                    </p>
                                    <p>
                                      Price : <span>${ep.details.price}</span>
                                    </p>
                                    <hr />
                                  </div>
                                )
                              );
                            })}
                          </Panel>
                        ) : (
                          <Panel
                            header={
                              <span style={{ fontWeight: "bold" }}>
                                Extra Products
                              </span>
                            }
                          >
                            <Empty description="No Extra Products selected" />
                          </Panel>
                        )}
                      </Collapse>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "250px",
                }}
              >
                <Empty description="Cart is Empty" />
              </div>
            )}
          </div>
          <div className="right">
            <Typography.Title level={4}>Cart Summary</Typography.Title>
            <p>
              Total Items : <span> {cart && cart.length}</span>
            </p>
            <p>
              Total Quantity : <span>{totalQuantity}</span>
            </p>

            <p>
              Total Amount :<span>{`$ ${totalAmount}`}</span>
            </p>
            <Button
              type="primary"
              loading={orderLoading}
              disabled={cart && cart.length > 0 ? false : true}
              onClick={() => {
                if (tableId) {
                  createOrder();
                } else {
                  swal("Select a Table First", "", "warning");
                }
              }}
            >
              CHECKOUT
            </Button>
            {role === "WORKER" ? (
              ""
            ) : (
              <Button
                type="primary"
                loading={shareLoading}
                disabled={cart && cart.length > 0 ? false : true}
                onClick={() => {
                  if (tableId) {
                    isShared?.length > 0
                      ? dispatch(cancelSharedOrder(isShared[0]._id))
                      : shareOrder();
                  } else {
                    swal("Select a Table First", "", "warning");
                  }
                  // const payload = returnPayload();
                  // const payload2 = {
                  //   products: [payload],
                  //   admin: mainAdminId,
                  //   table: tableId,
                  //   user: userId,
                  // };
                  // console.log("671", payload);
                  // await dispatch(shareOrderToTable(payload2));
                }}
              >
                {isShared?.length > 0
                  ? "Cancel Shared Order"
                  : "Share on Table"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
