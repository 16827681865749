import axios from "axios";

import { url, headers } from "../../constants";
import swal from "sweetalert";
import { getOrdersHistory } from "../index";

const userId = localStorage.getItem("userId");
const adminId = localStorage.getItem("mainAdminId");
// const tableId = localStorage.getItem("selectedTableId");

export const rateAdmin = (rate, comment, orderId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/rate-admin`,
        {
          userId,
          adminId,
          rate,
          orderId,
        },
        {
          headers: headers,
        }
      );

      const res2 = await axios.post(
        `${url}/user/comment-admin`,
        {
          userId,
          adminId,
          comment,
          orderId,
        },
        {
          headers: headers,
        }
      );
      if (res && res2) {
        dispatch(getOrdersHistory());
        swal("", "Admin Rated & Commented", "success");
        // dispatch({
        //   type: dashboardTypes.GET_CATEGORIES,
        //   payload: res.data.categories,
        // });
      } else {
        swal("", "Error! try Again!", "error");
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const rateProduct = (rate, comment, productId, orderId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/user/rate-product`,
        {
          userId,
          productId,
          rate,
          orderId,
        },
        {
          headers: headers,
        }
      );

      const res2 = await axios.post(
        `${url}/user/comment-product`,
        {
          userId,
          productId,
          comment,
          orderId,
        },
        {
          headers: headers,
        }
      );
      if (res && res2) {
        dispatch(getOrdersHistory());
        swal("", "Product Rated & Commented", "success");
        // dispatch({
        //   type: dashboardTypes.GET_CATEGORIES,
        //   payload: res.data.categories,
        // });
      } else {
        swal("", "Error! try Again!", "error");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductStats = (productId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${url}/user/get-product-stats/${productId}`,
        {
          headers: headers,
        }
      );
      if (res) {
        return res.data;
      }
    } catch (err) {
      console.log(err);
    }
  };
};
