import * as authTypes from "../types/authTypes";

const initialState = {
  login: false,
};

const authReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case authTypes.LOGIN:
      return { ...state, login: true };
    case authTypes.LOGOUT:
      return { ...state, login: false };
    default:
      return state;
  }
};

export default authReducer;
