import { useState } from "react";
import { Button, Typography, Tag, notification, Rate } from "antd";
import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  UserAddOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";

import Layout from "../layout/DashboardLayout";
import Counter from "../components/Counter";
import { url } from "../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  getProductById,
  shareOrderToTable,
  getProductStats,
  PlaceOrder,
  getUserShare,
  cancelSharedOrder,
  getAdminTableOrders,
} from "../redux";

const { CheckableTag } = Tag;

const CategoryItem = () => {
  const [ingredientTags, setIngredientTags] = useState([]);
  const [allergicTags, setAllergicTags] = useState([]);
  const [extraProductTags, setExtraProductTags] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [photos, setPhotos] = useState([]);
  //const [inCart, setInCart] = useState(false);
  const [canPostOrder, setCanPostOrder] = useState(null);

  const [orderLoading, setOrderLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  // const [isShared, setIsShared] = useState(null);

  const [productStatistics, setProductStats] = useState({});
  const [selectedUserName, setSelectedUsername] = useState("");
  // const [modalVisible, setModalVisible] = useState(false);
  // const modalChange = (modalVisible) => {
  //   setModalVisible(modalVisible);
  // };

  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const product = useSelector((state) => state.dashboardReducer.product);
  console.log("product", product);

  const cartState = useSelector((state) => state.dashboardReducer.cart);
  const isShared = useSelector((state) => state.dashboardReducer.sharedOrder);

  const customerList = useSelector((state) => state.workerReducer.tableOrders);

  console.log("64", customerList);

  console.log("c", isShared);

  const selectedAdminId = localStorage.getItem("selectedAdminId");
  const mainAdminId = localStorage.getItem("mainAdminId");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const tableId = localStorage.getItem("selectedTableId");

  useEffect(async () => {
    if (role !== "WORKER") {
      dispatch(getUserShare());
    }
    dispatch(getAdminTableOrders(tableId));

    handleGetMyLocation();
    const pix = await dispatch(getProductById(id));
    const productStats = await dispatch(getProductStats(id));
    setProductStats(productStats);
    console.log(productStats);
    let temp = [];
    if (pix && pix.length > 0) {
      pix &&
        pix.length > 0 &&
        pix.map((image, i) => {
          temp.push({
            original: image,
            thumbnail: image,
          });
        });
      setPhotos(temp);
    } else {
      setPhotos([]);
    }
  }, []);
  // console.log("90", isShared);

  const handleIngredientChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...ingredientTags, tag]
      : ingredientTags.filter((t) => t !== tag);
    // console.log("You are interested in: ", nextSelectedTags);

    setIngredientTags(nextSelectedTags);
  };
  const handleAllergicChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...allergicTags, tag]
      : allergicTags.filter((t) => t !== tag);
    // console.log("You are interested in: ", nextSelectedTags);

    setAllergicTags(nextSelectedTags);
  };
  const handleExtraProductChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...extraProductTags, tag]
      : extraProductTags.filter((t) => t !== tag);
    // console.log("You are interested in: ", nextSelectedTags);

    setExtraProductTags(nextSelectedTags);
  };

  const showNotification = (type, message) => {
    const config = {
      message: message,
      placement: "bottomLeft",
      style: {
        background: "#f7e8e8",
      },
      duration: 2,
    };
    type === "error"
      ? notification.error(config)
      : notification.success(config);
  };

  const showSuccess = (payload) => {
    dispatch(addToCart(payload));
    showNotification("success", "Added to cart");
  };

  const returnPayload = () => {
    let extraAmount = 0;
    let extraCost = 0;
    let exclude = [];
    let include = [];
    let allergicNames = [];
    let extraProducts = [];
    product.allergic &&
      product.allergic.length > 0 &&
      product.allergic.map((allergy) => {
        const index = allergicTags.indexOf(allergy);
        if (index === -1) {
          allergicNames.push({
            allergicName: allergy,
            active: false,
          });
        } else {
          allergicNames.push({
            allergicName: allergy,
            active: true,
          });
        }
      });

    product.extraProducts &&
      product.extraProducts.length > 0 &&
      product.extraProducts.map((extra) => {
        extraProductTags && extraProductTags.length > 0
          ? extraProductTags.map((extra2) => {
              if (extra2.name === extra.name) {
                extraProducts.push({
                  productName: extra.name,
                  active: true,
                  details: extra,
                });
                extraAmount += Number(extra.price);
                extraCost += Number(extra.price);
              }
            })
          : extraProducts.push({
              productName: extra.name,
              active: false,
              details: extra,
            });
      });

    product.ingrediants &&
      product.ingrediants.length > 0 &&
      product.ingrediants.map((ingrediant) => {
        const index = ingredientTags.indexOf(ingrediant);
        if (index === -1) {
          include.push(ingrediant);
        } else {
          exclude.push(ingrediant);
        }
      });

    const payload = {
      product: product._id,
      quantity,
      allergicNames,
      extraAmount,
      extraCost,
      extraProducts,
      include,
      exclude,
    };
    return payload;
  };

  const handleAddToCart = () => {
    // const payload = {
    //   ...product,
    //   ingredients: ingredientTags.length > 0 ? ingredientTags : [],
    //   allergies: allergicTags.length > 0 ? allergicTags : [],
    //   extraProducts: extraProductTags.length > 0 ? extraProductTags : [],
    //   quantity,
    //   // totalPrice: Number(product.price) * quantity,
    // };
    let payload = returnPayload();
    const newPayload = {
      ...payload,
      product: product,
    };

    if (selectedAdminId === mainAdminId) {
      if (cartState.length > 0) {
        const hasId = cartState.findIndex((item) => item._id === product._id);
        hasId !== -1
          ? showNotification("error", "Already In Cart")
          : showSuccess(newPayload);
      } else {
        showSuccess(newPayload);
      }
    } else {
      swal("Error", "You are in View Mode only", "error");
    }
  };

  const handleGetMyLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position.coords.accuracy)
        // console.log([position.coords.latitude, position.coords.longitude])
        // console.log('handleGetAdminLocation');

        const adminId = localStorage.getItem("selectedAdminId");

        axios
          .post(`${url}/admin/admin-location`, { adminId })
          .then((res) => {
            // console.log(res.data.admin.location)
            // this.setState({ adminLocation: res.data.admin.location })

            const R = 6371e3; // metres
            const φ1 = (res.data.admin.location.lat * Math.PI) / 180; // φ, λ in radians
            const φ2 = (position.coords.latitude * Math.PI) / 180;
            const Δφ =
              ((position.coords.latitude - res.data.admin.location.lat) *
                Math.PI) /
              180;
            const Δλ =
              ((position.coords.longitude - res.data.admin.location.lng) *
                Math.PI) /
              180;

            const a =
              Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const d = R * c; // in metres

            // console.log(position.coords.accuracy + " Accuracy");
            // console.log(d + " d");
            // console.log(res.data.admin.location.lat + "a lat");
            // console.log(res.data.admin.location.lng + "a lng");

            // console.log(position.coords.latitude + " lat");
            // console.log(position.coords.longitude + " lng");

            let accurayMeasure =
              position.coords.accuracy >= 800 ? position.coords.accuracy : 800;

            setCanPostOrder(!(d >= accurayMeasure));
            // this.setState({
            //     canPostOrder: true
            // })
          })
          .catch((err) => {
            console.log(err);
          });
      },
      (e) => {
        setCanPostOrder(null);
        console.log(e);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  console.log("can post ", canPostOrder);
  const PostOrder = async () => {
    // handleGetMyLocation();
    let payload = returnPayload();
    // console.log(payload);
    // dispatch(PlaceOrder([payload], "single", history));
    // setOrderLoading(true);
    if (selectedAdminId === mainAdminId) {
      setOrderLoading(true);

      if (role === "WORKER") {
        userId
          ? Swal.fire({
              title: `Order Will be placed By`,
              input: "select",
              inputOptions: customerList?.map((customer) => {
                return customer?.user?.name;
              }),
              inputPlaceholder: "Select Customer",
              showCancelButton: true,
              showCloseButton: true,
              showDenyButton: true,
              showConfirmButton: true,
              confirmButtonText: "Place Order",
              denyButtonText: "New User",
              inputValidator: async (value) => {
                return new Promise((resolve) => {
                  if (value !== "") {
                    // console.log(customerList[value]?.user?.name);
                    setSelectedUsername(customerList[value]?.user?.name);
                    resolve();
                  } else {
                    // dispatch(PlaceOrder([payload], "single", history));
                    // setOrderLoading(false);
                    // console.log(value);
                    Swal.fire(`Please Select User `, "", "info");
                    resolve();
                  }
                });
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                console.log(result);
                await dispatch(PlaceOrder([payload], "single", history));
                setOrderLoading(false);
              } else if (result.isDenied) {
                Swal.fire({
                  title: "New User Name",
                  input: "text",
                  inputAttributes: {
                    autocapitalize: "off",
                  },
                  showCancelButton: true,
                  confirmButtonText: "Place Order",
                  showLoaderOnConfirm: true,
                  inputValidator: async (newValue) => {
                    return new Promise(async (resolve) => {
                      if (newValue !== "") {
                        const res = await axios.post(
                          `${url}/user/guest-signup`,
                          {
                            name: newValue,
                          }
                        );
                        if (res) {
                          localStorage.setItem("userId", res.data.doc._id);
                          localStorage.setItem("userName", res.data.doc.name);
                          dispatch(PlaceOrder([payload], "single", history));
                          setOrderLoading(false);
                        }
                        setSelectedUsername(newValue);
                        resolve();
                      } else {
                        // dispatch(PlaceOrder([payload], "single", history));
                        // setOrderLoading(false);
                        // console.log(value);
                        Swal.fire(`Please Specify User Name!`, "", "info");
                        resolve();
                        setOrderLoading(false);
                      }
                    });
                  },
                }).then((result2) => {
                  console.log();
                });
              }
            })
          : swal("Please Specify User Name", {
              content: "input",
            }).then(async (value) => {
              if (value === "") {
                swal("", "Name is Required", "error");
              } else {
                const res = await axios.post(`${url}/user/guest-signup`, {
                  name: value,
                });
                if (res) {
                  localStorage.setItem("userId", res.data.doc._id);
                  localStorage.setItem("userName", res.data.doc.name);
                  dispatch(PlaceOrder([payload], "single", history));
                  setOrderLoading(false);
                }
              }
            });
      } else {
        if (canPostOrder === null) {
          await handleGetMyLocation();
          setOrderLoading(false);

          //   navigator.geolocation.getCurrentPosition(async (position) => {
          //   if (!position?.coords) {
          //     if (!canPostOrder) {
          //       swal("Error", "Not in Range to Place Order", "error");
          //       setOrderLoading(false);
          //     } else {
          //       // swal("Error", "Not in Range to Place Order", "error");
          //       setOrderLoading(true);
          //       await dispatch(PlaceOrder([payload], "single", history));
          //       setOrderLoading(false);
          //     }
          //   } else {
          //     await dispatch(PlaceOrder([payload], "single", history));
          //     await handleGetMyLocation();
          //     setOrderLoading(false);
          //   }
          // });
        } else if (!canPostOrder) {
          swal("Error", "Not in Range to Place Order", "error");
          setOrderLoading(false);
        } else {
          await dispatch(PlaceOrder([payload], "single", history));
          setOrderLoading(false);
        }
      }
    } else {
      swal("Error", "You are in View Mode only", "error");
      setOrderLoading(false);
    }
  };

  return (
    <Layout>
      {product && (
        <div className="category-item">
          <Button
            shape="round"
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.goBack()}
          >
            Back to Categories
          </Button>
          <div style={{ width: "80%", margin: "20px auto" }}>
            {photos && photos.length > 0 && <ImageGallery items={photos} />}
          </div>
          <div className="header">
            <Typography.Title
              level={2}
              style={{ textTransform: "capitalize", marginBottom: "20px" }}
            >
              {product && product.name}
            </Typography.Title>
            {productStatistics && (
              // productStatistics.averageRating !== null &&
              // productStatistics.timesOrdered !== 0 &&
              // productStatistics.timesRated !== 0 &&
              <div className="stat-card">
                <p>
                  Average Rating :{" "}
                  <Rate
                    value={
                      productStatistics && productStatistics.averageRating
                        ? productStatistics.averageRating
                        : 0
                    }
                    disabled
                  />{" "}
                  (
                  {productStatistics && productStatistics.averageRating
                    ? productStatistics.averageRating
                    : 0}
                  )
                </p>
                <p>
                  Times Rated :{" "}
                  <span>
                    {productStatistics && productStatistics.timesRated}
                  </span>
                </p>
                <p>
                  Times Ordered :{" "}
                  <span>
                    {productStatistics && productStatistics.timesOrdered}
                  </span>
                </p>
              </div>
            )}
          </div>

          <div className="order-section">
            {product &&
              product.promotion &&
              product.promotion.total &&
              product.promotion.total.indexOf(userId) === -1 &&
              product.promotion.buyQuantity && (
                <Typography.Title
                  level={5}
                  style={{
                    textTransform: "uppercase",
                    color: "red",
                    backgroundColor: "lightyellow",
                    width: "max-content",
                    padding: "3px 15px",
                    margin: "20px auto",
                    borderRadius: "4px",
                    display: "flex",
                  }}
                >
                  {`This Product Contains Offer (Order ${product.promotion.buyQuantity} Quantity & Get ${product.promotion.freeQuantity} Free)`}
                </Typography.Title>
              )}
            <Typography.Title level={4}>Order Info</Typography.Title>

            <Typography.Title level={4}>
              Price :{" "}
              {product &&
              product.promotion &&
              product.promotion.discountPercentage ? (
                product.discountedPrice ? (
                  <>
                    <span
                      style={{
                        textDecoration: "line-through",
                        marginRight: "20px",
                      }}
                    >
                      TL {product.price}
                    </span>
                    TL {Math.round(product.discountedPrice).toFixed(1)}
                  </>
                ) : (
                  `TL ${product.price}`
                )
              ) : (
                `TL ${product.price}`
              )}
            </Typography.Title>
            <Typography.Title
              level={5}
              style={{
                marginBottom: "30px",
              }}
            >
              Description : {product && product.description}
            </Typography.Title>
            {product &&
            product.ingrediants &&
            product.ingrediants.length > 0 ? (
              <Typography.Text
                type="secondary"
                style={{
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  fontSize: "15px",
                }}
              >
                Select Those Ingredients which you donot want in your order
              </Typography.Text>
            ) : (
              ""
            )}
            <Typography.Title level={5}>
              {product &&
              product.ingrediants &&
              product.ingrediants.length > 0 ? (
                <span style={{ marginRight: 8 }}>Ingredients :</span>
              ) : (
                ""
              )}
              {product && product.ingrediants && product.ingrediants.length > 0
                ? product.ingrediants.map((ingredient) => (
                    <CheckableTag
                      key={ingredient}
                      style={{
                        border: "1px solid #1F2836",
                        fontSize: "13px",
                        padding: "2px 10px",
                        margin: "10px",
                      }}
                      checked={ingredientTags.indexOf(ingredient) > -1}
                      onChange={(checked) =>
                        handleIngredientChange(ingredient, checked)
                      }
                    >
                      {ingredient}
                    </CheckableTag>
                  ))
                : ""}
            </Typography.Title>
            <Typography.Title level={5}>
              {product && product.allergic && product.allergic.length > 0 ? (
                <span style={{ marginRight: 8 }}>Allergies :</span>
              ) : (
                ""
              )}
              {product && product.allergic && product.allergic.length > 0
                ? product.allergic.map((allergy) => (
                    <CheckableTag
                      key={allergy}
                      style={{
                        border: "1px solid #1F2836",
                        fontSize: "13px",
                        padding: "2px 10px",
                      }}
                      checked={allergicTags.indexOf(allergy) > -1}
                      onChange={(checked) =>
                        handleAllergicChange(allergy, checked)
                      }
                    >
                      {allergy}
                    </CheckableTag>
                  ))
                : ""}
            </Typography.Title>
            {product && product.waitTime !== "" ? (
              <Typography.Title level={5}>
                <ClockCircleOutlined /> Wait Time :{" "}
                {product && product.waitTime}
              </Typography.Title>
            ) : (
              ""
            )}
            {product && product.calories !== "" ? (
              <Typography.Title level={5}>
                Calories : {product && product.calories}
              </Typography.Title>
            ) : (
              ""
            )}
            <Typography.Title level={5}>
              {product &&
              product.extraProducts &&
              product.extraProducts.length > 0 ? (
                <span style={{ marginRight: 8 }}>Add Extra Products :</span>
              ) : (
                ""
              )}
              {product &&
              product.extraProducts &&
              product.extraProducts.length > 0
                ? product.extraProducts.map((extraPro) => (
                    <CheckableTag
                      key={extraPro}
                      style={{
                        border: "1px solid #1F2836",
                        fontSize: "13px",
                        padding: "2px 10px",
                      }}
                      checked={extraProductTags.indexOf(extraPro) > -1}
                      onChange={(checked) =>
                        handleExtraProductChange(extraPro, checked)
                      }
                    >
                      {`${extraPro.name} for TL ${extraPro.price}`}
                    </CheckableTag>
                  ))
                : ""}
            </Typography.Title>
            <Typography.Title level={5} className="quantity">
              <UserAddOutlined className="icon" /> Quantity :{" "}
              <Counter increment={setQuantity} isCart={false} />
            </Typography.Title>
            {product && product.totalStock === 0 && (
              <Typography.Title
                level={5}
                style={{
                  textTransform: "uppercase",
                  color: "red",
                  backgroundColor: "lightyellow",
                  width: "max-content",
                  padding: "3px 15px",
                  margin: "50px 0",
                  borderRadius: "4px",
                }}
              >
                Product is Currently Out of Stock
              </Typography.Title>
            )}
            {/* <div className="buttons">
              <Button
                type="primary"
                icon={<ShoppingCartOutlined />}
                onClick={handleAddToCart}
                disabled={product && product.totalStock === 0 ? true : false}
              >
                Add To Cart
              </Button>

              <Button type="primary" onClick={() => history.goBack()}>
                Choose Another Product
              </Button>
              <Button type="primary" onClick={() => history.push("/user/cart")}>
                Goto Cart
              </Button>
            </div> */}
            <div className="buttons">
              <Button
                type="primary"
                className="place-order"
                loading={orderLoading}
                disabled={product && product.totalStock === 0 ? true : false}
                onClick={async () => {
                  if (tableId) {
                    PostOrder();
                    // setOrderLoading(true);
                    // await PostOrder();
                    // setOrderLoading(false);
                  } else {
                    swal("Select a Table First", "", "warning");
                  }
                }}
              >
                Place Order
              </Button>
              {role === "WORKER" ? (
                ""
              ) : (
                <Button
                  type="primary"
                  className="place-order"
                  loading={shareLoading}
                  disabled={product && product.totalStock === 0 ? true : false}
                  onClick={async () => {
                    if (selectedAdminId === mainAdminId) {
                      setShareLoading(true);

                      if (canPostOrder === null) {
                        await handleGetMyLocation();
                        setShareLoading(false);
                      } else if (!canPostOrder) {
                        swal("Not in Range to Share Order", "Error", "error");
                        setShareLoading(false);
                      } else {
                        const payload = returnPayload();
                        const payload2 = {
                          products: [payload],
                          admin: mainAdminId,
                          table: tableId,
                          user: userId,
                        };
                        console.log("671", payload);
                        isShared.length > 0
                          ? await dispatch(cancelSharedOrder(isShared[0]?._id))
                          : await dispatch(shareOrderToTable(payload2));

                        setShareLoading(false);
                      }
                    } else {
                      swal("Error", "You are in View Mode only", "error");
                      setShareLoading(false);
                    }
                  }}
                >
                  {isShared?.length > 0
                    ? "Cancel Shared Order"
                    : "Share on Table"}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CategoryItem;
