import * as generalTypes from "../types/generalTypes";

const initialState = {
  allCountries: [],
  allCities: [],
  userInfo: {},
};

const generalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case generalTypes.GET_ALL_COUNTRIES:
      return {
        ...state,
        allCountries: payload,
      };
    case generalTypes.GET_ALL_CITIES:
      return {
        ...state,
        allCities: payload,
      };
    case generalTypes.GET_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      };

    default:
      return state;
  }
};

export default generalReducer;
