import { Typography, Empty } from "antd";

const MyPromotions = ({ Data }) => {
  const { Title } = Typography;
  // const promotions = useSelector(
  //   (state) => state.promotionReducer.myPromotions
  // );
  console.log(Data);

  return (
    <div>
      {Data && Data.length > 0 ? (
        Data.map(({ promotion, admin }) => {
          // console.log(promotion, admin);
          return (
            <div key={promotion._id} className="my-promotion">
              <Title level={5}>Restaurant Name : {admin.name}</Title>
              <Title level={5}>
                Promotion Type : {promotion.promotionType}
              </Title>
              <Title level={5}>
                Discount On All Products : {promotion.percentage} %
              </Title>
            </div>
          );
        })
      ) : (
        <Empty description="No Promotions" />
      )}
    </div>
  );
};

export default MyPromotions;
