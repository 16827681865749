import BackgroundSlider from "react-background-slider";
import { useState } from "react";
import { Button, Row, Col, Typography, Divider, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiOutlineQrcode } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

import image1 from "../assets/slider1.jpeg";
import image2 from "../assets/slider2.jpeg";
import image3 from "../assets/slider3.jpeg";
import logo from "../assets/qrlogo.png";

import info1 from "../assets/info1.jpeg";
import info2 from "../assets/info2.jpeg";
import vision1 from "../assets/vision1.jpeg";
import vision2 from "../assets/vision2.jpeg";
import whyUs from "../assets/why2.jpg";

import fb from "../assets/fb.png";
import insta from "../assets/insta.png";
import twitter from "../assets/twitter.png";
import pinterest from "../assets/pinterest.png";

const Home = (props) => {
  const [hideTransparency, setHideTransparency] = useState(false);

  window.onscroll = () => {
    if (
      document.body.scrollTop > 200 ||
      document.documentElement.scrollTop > 200
    ) {
      setHideTransparency(true);
    } else {
      setHideTransparency(false);
    }
  };

  console.log(hideTransparency);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
    // desktop: {
    //   breakpoint: { max: 3000, min: 1024 },
    //   items: 1,
    // },
    // tablet: {
    //   breakpoint: { max: 1024, min: 464 },
    //   items: 1,
    // },
    // mobile: {
    //   breakpoint: { max: 464, min: 0 },
    //   items: 1,
    // },
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="landing-page">
      <BackgroundSlider
        images={[image1, image2, image3]}
        duration={7}
        transition={2}
      />
      <div id="home" className="hero-section">
        <div
          className={
            hideTransparency ? "navbar-desktop navbar-fixed" : "navbar-desktop"
          }
        >
          <Typography.Title level={3}>
            <img src={logo} alt="" width="60px" height="60px" />
            QRTREAT
          </Typography.Title>
          <div className="buttons">
            <ul>
              <li>
                <a href="#home">HOME</a>
              </li>
              <li>
                <a href="#services">SERVICES</a>
              </li>
              <li>
                <a href="#about">ABOUT US</a>
              </li>
              <li>
                <a href="#vision">VISION</a>
              </li>
              <li>
                <a href="#testimonials">TESTIMONIALS</a>
              </li>
              {/* <li>CONTACT</li> */}
            </ul>
          </div>
          <div className="desk-buttons">
            <Button>
              <Link to="/user-login">User Login</Link>
            </Button>
            <Button>
              <a href="https://vendor.qrtreat.com">Admin Login</a>
            </Button>
            <Button>
              <Link to="/worker-login">Worker Login</Link>
            </Button>
          </div>
        </div>
        <div
          className={
            hideTransparency ? "navbar-mobile navbar-fixed" : "navbar-mobile"
          }
        >
          <Typography.Title level={3}>
            <img src={logo} alt="" width="60px" height="60px" />
            QRTREAT
          </Typography.Title>
          <Button
            icon={<MenuOutlined alt="" />}
            onClick={showDrawer}
            className="menu-button"
          />
          <Drawer
            placement={"right"}
            closable={true}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ padding: "50px 0" }}
          >
            <div className="drawer">
              <ul className="mobile-menu">
                <li onClick={onClose}>
                  <a href="#home">HOME</a>
                </li>
                <li onClick={onClose}>
                  <a href="#services">SERVICES</a>
                </li>
                <li onClick={onClose}>
                  <a href="#about">ABOUT US</a>
                </li>
                <li onClick={onClose}>
                  <a href="#vision">VISION</a>
                </li>
                <li onClick={onClose}>
                  <a href="#testimonials">TESTIMONIALS</a>
                </li>
                {/* <li>CONTACT</li> */}
              </ul>
              <div className="Buttons">
                <Button>
                  <Link to="/user-login">User Login</Link>
                </Button>
                <Button>
                  <a href="https://scaneateryvendor.herokuapp.com">
                    Admin Login
                  </a>
                </Button>
                <Button>
                  <Link to="/worker-login">Worker Login</Link>
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
        <div className="hero-title">
          <p>DINING THROUGH</p>
          <Typography.Title level={1}>qrtreat</Typography.Title>
          <p>
            We offer a highly seasonal and continuously evolving tasting menu
            experience.
          </p>
          <p></p>
        </div>
      </div>
      <div className="info-section">
        <img src={info1} alt="" />
        <div className="center-text">
          <div className="heading">
            <p>welcome</p>
            <Typography.Title level={1}>
              {/* Dining Through Qrtreat */}
              Who We Are?
            </Typography.Title>
          </div>
          <ul>
            <li>
              A great way to treat your business and to your customers while
              your business is running and your customers having the experience
              at your venue.
            </li>
            <li>
              Qrtreat combines the power of technology and your service and
              creats the modern, easy and efficient exprience with 100%
              satifaction for your customer.
            </li>
            <li>
              You can creat your menu and help customer to customize their
              experinece according to their taste in a very easy understandable
              way.
            </li>
            {/* <li>
              You can use your laptop, pc, tablet and phone to run everyting
              witout downloading a spesific aplication.
            </li>
            <li>
              Registered customer can find you easily in the system and get the
              live information about your venue.
            </li>
            <li>
              You also can track your customars and give special offers,
              discounts and free product for your loyal customar.
            </li>
            <li>
              Not last but least there are more benefits that is ready for you
              to use and grow your business.
            </li> */}
          </ul>
          {/* <p>
            For a truly memorable dining experience, cuisine and atmosphere are paired as
            thoughtfully as food and wine. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco. Quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt
            . Animi, id est laborum et dolorum fuga. Nam libero.
        </p> */}
          {/* <Button>Join Today</Button> */}
        </div>
        <img src={info2} alt="" />
      </div>
      <div id="services" className="services">
        <div className="heading">
          <p>features</p>
          <Typography.Title level={1}>Services we Offer</Typography.Title>
        </div>
        <Row gutter={[40, 40]} style={{ width: "90%", margin: "30px auto" }}>
          <Col xs={24} md={8}>
            <div className="service-item">
              <AiOutlineQrcode />
              <h4>Scan QR Code</h4>
              <p>Any QR code reader app can be used, Get orders by QR Code.</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="service-item">
              <FaMapMarkerAlt />
              <h4>Can Search through Map</h4>
              <p>
                Customers can search nearby restaurants in their house radius.
              </p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="service-item">
              <MdPayment />
              <h4>Payment Choices</h4>
              <p>Pre, after and no payment choices.</p>
            </div>
          </Col>
        </Row>
      </div>
      <div id="vision" className="vision">
        <div className="heading">
          <p>concept</p>
          <Typography.Title level={1}>Culinary Vision</Typography.Title>
        </div>
        <p className="info">
          Qrtreat combines the power of technology and your service and creats
          the modern, easy and efficient exprience with 100% satifaction for
          your customer.
          <br />
          You can use your laptop, pc, tablet and phone to run everyting witout
          downloading a spesific aplication.
          <br />
          Registered customer can find you easily in the system and get the live
          information about your venue.
          <br />
          You also can track your customars and give special offers, discounts
          and free product for your loyal customar.
          <br />
          Not last but least there are more benefits that is ready for you to
          use and grow your business.
        </p>
        <Row gutter={[40, 40]} style={{ width: "90%", margin: "0 auto" }}>
          <Col xs={24} md={12}>
            <img src={vision1} width="100%" alt="" />
          </Col>
          <Col xs={24} md={12}>
            <img src={vision2} width="100%" alt="" />
          </Col>
        </Row>
      </div>
      <div id="about" className="why-us">
        <div className="heading">
          <p>about</p>
          <Typography.Title level={1}>Why Qrtreat?</Typography.Title>
        </div>
        <Row
          gutter={[40, 40]}
          align="middle"
          style={{ width: "90%", margin: "30px auto" }}
        >
          <Col xs={24} md={12}>
            <img src={whyUs} alt="" width="100%" height="auto" />
          </Col>
          <Col xs={24} md={12}>
            <h3>
              Because you can get fast, quality and sustainable service from us
              so you can give a the same to your customers.
              <br />
              <br />
              Because we are thinging for you and you can contact to us 7/24.
              <br />
              <br />
              Because our goal is to make the life easier for everyone.
              <br />
              <br />
              Because we like to lower your costs so you can get more customer
              and they’ll be happier with your low coasts.
              <br />
              <br />
              Once you try, you will see there are dozens of reasons to choose
              us.
              <br />
              <br />
              You can Call Waiter through the Website.
              <br />
              <br />
              Restaurant Owners can Check and Manage their Stock control
              <br />
              <br />
              One click customer bill separation
            </h3>
          </Col>
        </Row>
      </div>
      <div id="testimonials" className="testimonial">
        <div className="testimonial-inner">
          <div className="heading">
            <p>testimonials</p>
            <Typography.Title level={1}>What Clients Say</Typography.Title>
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={2000}
            // keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            removeArrowOnDeviceType={[
              "tablet",
              "mobile",
              "superLargeDesktop",
              "desktop",
            ]}
            deviceType={props.deviceType}
          >
            <div>
              <p className="review">
                "In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
                Nullam dictum felis eu pede mollis pretium. Integer tincidunt.
                Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate
                eleifend tellus. Aenean leo ligula, porttitor eu, consequat.
                Duis leo. Sed fringilla mauris sit amet nibh."
              </p>
              <Divider />
              <span className="name">Laura Foster</span>
              <br />
              <span className="company">Agro</span>
            </div>
            <div>
              <p className="review">
                "Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
                sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenatis vitae, justo. Itaque earum
                rerum hic tenetur a sapiente delectus. Accusantium doloremque."
              </p>
              <Divider />
              <span className="name">Angela Ray</span>
              <br />
              <span className="company">Rays</span>
            </div>
            {/* <div>Item 3</div>
  <div>Item 4</div> */}
          </Carousel>
        </div>
      </div>
      {/* <div className="contact">
        <div className="heading">
          <p>contact</p>
          <Typography.Title level={1}>Write us a message</Typography.Title>
        </div>

        <div className="form">
          <Form onFinish={onFinish} layout="vertical" className="contact-form">
            <div className="form-row">
              <Form.Item name="fname" className="form-item">
                <Input placeholder={"First Name..."} />
              </Form.Item>
              <Form.Item name="lname" className="form-item">
                <Input placeholder={"Last Name..."} />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item name="phone" className="form-item">
                <Input placeholder={"Phone..."} />
              </Form.Item>
              <Form.Item name="email" className="form-item">
                <Input placeholder={"Email..."} />
              </Form.Item>
            </div>
            <Form.Item name="message">
              <Input.TextArea
                rows="6"
                className="textarea"
                placeholder={"Message..."}
              />
            </Form.Item>
            <Form.Item>
              <Button>Send Message</Button>
            </Form.Item>
          </Form>
        </div>
      </div> */}
      <footer>
        <Row
          gutter={[30, 30]}
          justify="center"
          style={{ width: "90%", margin: "0 auto", paddingBottom: "30px" }}
        >
          <Col xs={24} md={12} lg={8}>
            <div>
              <h2>
                <img src={logo} alt="" width="50px" height="50px" />
                Qrtreat
              </h2>
              <p>
                Qrtreat combines the power of technology and your service and
                creats the modern, easy and efficient exprience with 100%
                satifaction for your customer.
              </p>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div>
              <h3>ADDRESS</h3>
              <p>
                Balikesir Technology development field 3rd floor, door no:1
                Teknokent Balikesir University Cagis Campus Balıkesir/Turkey
                <br />
                <br />
                P: +902666121400
                <br />
                E: info@qrtreat.com
              </p>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <h3>HOURS</h3>
            <p>
              Monday – Sunday
              <br />
              Lunch: 12PM – 2PM
              <br />
              Dinner: 6PM – 10PM
              <br />
              <br />
              Happy Hours: 4PM – 6PM
            </p>
          </Col>
          {/* <Col xs={24} md={12} lg={6}>
            <h3>MORE INFO</h3>
            <p>
              <a>Careers</a>
              <br />
              <br />
              <a>Get in Touch</a>
              <br />
              <br />
              <a>Privacy Policy</a>
            </p>
          </Col> */}
        </Row>
        <div className="bottom-footer">
          <span style={{ color: "#999999" }}>
            Copyright © 2021, Qrtreat Pvt. Ltd. Designed by{" "}
            <a
              style={{ fontSize: "14px", color: "#0981D3" }}
              href="https://www.decotechs.com"
              target="_blank"
            >
              Decotechs
            </a>
            {/* Decotechs */}
          </span>
          <div>
            <img alt="" src={fb} width="10px" height="auto" />
            <img alt="" src={insta} width="25px" height="auto" />
            <img alt="" src={twitter} width="30px" height="auto" />
            <img alt="" src={pinterest} width="25px" height="auto" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
