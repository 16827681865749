import { Typography } from 'antd';

import Layout from '../layout/DashboardLayout';
import MapComp from '../components/Map';

const AttendedVenue = () => {
  return (
    <Layout active='attended-venue'>
      <Typography.Title level={3}>List of Visited Restaurants</Typography.Title>
      <MapComp />
    </Layout>
  );
};

export default AttendedVenue;
