import { Badge, Menu } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { FaHistory } from "react-icons/fa";
import { GiTable } from "react-icons/gi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const LayoutMenu = ({ active }) => {
  const cart = useSelector((state) => state.dashboardReducer.cart);
  const tableSelected = useSelector(
    (state) => state.dashboardReducer.tableSelected
  );

  console.log("16", tableSelected);
  const role = localStorage.getItem("role");
  const adminId = localStorage.getItem("mainAdminId");
  const tableId = localStorage.getItem("selectedTableId");

  return (
    <Menu theme="dark" defaultSelectedKeys={active} mode="inline">
      {role === "WORKER" ? (
        <Menu.Item
          key="home"
          icon={<HomeOutlined />}
          disabled={tableSelected ? false : true}
        >
          <Link to="/user">Home</Link>
        </Menu.Item>
      ) : (
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/user">Home</Link>
        </Menu.Item>
      )}
      {role !== "WORKER" && (
        <Menu.Item key="find-venue" icon={<UserOutlined />}>
          <Link to="/user/find-venue">Find Venue</Link>
        </Menu.Item>
      )}
      {/* <Menu.Item key="attended-venue" icon={<FaBookReader />}>
        <Link to="/user/attended-venue">Attended Venue</Link>
      </Menu.Item> */}
      {/* {role === "WORKER" ? (
          <Menu.Item
            key="cart"
            disabled={tableSelected ? false : true}
            icon={
              cart && cart.length > 0 ? (
                <Badge count={cart.length} style={{ color: "#EFF0F1" }}>
                  <ShoppingCartOutlined
                    style={{ color: "#EFF0F1", fontSize: "24px", marginLeft: -4 }}
                  />
                </Badge>
              ) : (
                <ShoppingCartOutlined />
              )
            }
          >
            <Link to="/user/cart">Cart</Link>
          </Menu.Item>
        ) : (
          <Menu.Item
            key="cart"
            icon={
              cart && cart.length > 0 ? (
                <Badge count={cart.length} style={{ color: "#EFF0F1" }}>
                  <ShoppingCartOutlined
                    style={{ color: "#EFF0F1", fontSize: "24px", marginLeft: -4 }}
                  />
                </Badge>
              ) : (
                <ShoppingCartOutlined />
              )
            }
          >
            <Link to="/user/cart">Cart</Link>
          </Menu.Item>
        )} */}

      {role === "USER" &&
        // ||
        //   role === "WORKER" ||
        adminId !== "undefined" &&
        tableId !== "undefined" && (
          <>
            <Menu.Item key="history" icon={<FaHistory />}>
              <Link to="/user/history">Order History</Link>
            </Menu.Item>
            <Menu.Item key="settings" icon={<SettingOutlined />}>
              <Link to="/user/settings">Settings</Link>
            </Menu.Item>
          </>
        )}
      {role === "WORKER" && (
        <Menu.Item key="tables" icon={<GiTable />}>
          <Link to="/user/tables">View Tables</Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default LayoutMenu;
