import { useState } from "react";
import { Typography, Button } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Layout from "../layout/DashboardLayout";
import MapComp from "../components/Map";
import { getSelectedAdminTables } from "../redux";

const FindVenue = () => {
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [tables, setTables] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  // const [admin, setAdmin] = useState({});

  // useEffect(async () => {
  //   const details = await dispatch(getAdminDetails());
  //   setAdmin(details);
  // }, []);

  console.log(selectedAdmin);
  const selectAdmin = async (admin) => {
    const Tables = await dispatch(getSelectedAdminTables(admin._id));
    setTables(Tables);
    console.log(tables);
    setSelectedAdmin(admin);
  };

  return (
    <Layout active="find-venue">
      <Typography.Title level={3}>
        Find Venue Nearest to Your Location
      </Typography.Title>
      <MapComp selectAdmin={selectAdmin} />

      {selectedAdmin && (
        <>
          <div
            style={{
              // textAlign: "center",
              width: "100%",
              // backgroundColor: "red",
              margin: "40px 0",
            }}
          >
            <img
              alt=""
              src={selectedAdmin.logo}
              style={{ width: "200px", height: "auto" }}
            />
          </div>
          <p>{"Name : " + selectedAdmin.name}</p>
          <p>{"Address : " + selectedAdmin.address}</p>
          <p>{"Number of Tables : " + tables.tables}</p>
          <p>{"Available Tables : " + (tables.tables - tables.tablesActive)}</p>
          <p>
            {"Contact Number : " +
              (selectedAdmin.contactNumber
                ? selectedAdmin.contactNumber
                : "Admin Registration In Process")}
          </p>

          <Button
            style={{
              backgroundColor: "#1f2836",
              color: "white",
              marginTop: 10,
            }}
            onClick={() => {
              localStorage.setItem("selectedAdminId", selectedAdmin._id);
              history.replace("/user");
              window.location.reload();
            }}
          >
            View Menu
          </Button>
        </>
      )}
    </Layout>
  );
};

export default FindVenue;
