import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import qrlogo from '../assets/qrlogo.png';
import LayoutMenu from './LayoutMenu';

const MenuStyle = {
  cursor: 'pointer',
  background: 'transparent',
  color: 'white',
  border: 'none',
  outline: 'none',
  fontSize: '20px',
  height: 'auto',
};

const DashboardLayoutMobile = ({ active }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showDrawer} style={MenuStyle}>
        <MenuOutlined />
      </Button>
      <Drawer
        className='mobile-menu-drawer'
        placement='left'
        closable
        onClose={onClose}
        visible={visible}
      >
        <div className='logo'>
          <img
            src={qrlogo}
            alt='qrlogo'
            height={'70px'}
            width={'70px'}
            className='logoImg'
          />
        </div>
        <LayoutMenu active={active} />
      </Drawer>
    </>
  );
};

export default DashboardLayoutMobile;
