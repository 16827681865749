import { useState } from "react";
import { Button, Row, Col, Typography, Empty, Table } from "antd";
import { Link } from "react-router-dom";

import Layout from "../layout/DashboardLayout";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  callWaiter,
  getCategories,
  getTableOrders,
  getAdminDetails,
  getWorkerOrders,
  getUserPromotions,
} from "../redux";

const UserDashboard = () => {
  const [checkBill, setCheckBill] = useState(false);
  //const [extraProductDetailsPrice, setExtraProductDetailsPrice] = useState("0");
  const [adminDetails, setAdminDetails] = useState([]);
  const [waiterLoading, setWaiterLoading] = useState(false);

  const dispatch = useDispatch();

  let role = localStorage.getItem("role");
  const adminId = localStorage.getItem("mainAdminId");
  const tableId = localStorage.getItem("selectedTableId");

  const categories = useSelector((state) => state.dashboardReducer.categories);
  const userPromotions = useSelector(
    (state) => state.dashboardReducer.userPromotions
  );
  const activeOrders = useSelector(
    (state) => state.dashboardReducer.activeOrders
  );

  // const reloadPage = () => {
  //   var currentDocumentTimestamp = new Date(
  //     performance.timing.domLoading
  //   ).getTime();
  //   // Current Time //
  //   var now = Date.now();
  //   // Total Process Length as Minutes //
  //   var tenSec = 5 * 1000;
  //   // End Time of Process //
  //   var plusTenSec = currentDocumentTimestamp + tenSec;
  //   if (now > plusTenSec) {
  //     window.location.reload();
  //   }
  // };

  useEffect(async () => {
    // reloadPage();
    dispatch(getCategories());
    dispatch(getUserPromotions());
    const data = await dispatch(getAdminDetails());
    setAdminDetails(data);
    console.log(role);
    if (role === "WORKER") {
      dispatch(getWorkerOrders());
    } else {
      dispatch(getTableOrders());
    }
  }, []);

  console.log(userPromotions);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Start On",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "Ends On",
      dataIndex: "endDate",
      key: "endDate",
    },
  ];

  return (
    <Layout active="home">
      <div className="user-main">
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={adminDetails && adminDetails.logo}
            alt="restaurant-logo"
            height={"120px"}
            width="120px"
          />
          <br />
          <div>
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>
              <span>{adminDetails && adminDetails.name}</span>
            </p>
          </div>
          <div className="buttons">
            {role !== "WORKER" ? (
              adminId !== "undefined" &&
              tableId !== "undefined" && (
                <Button
                  loading={waiterLoading}
                  type="primary"
                  onClick={async () => {
                    setWaiterLoading(true);
                    await dispatch(callWaiter());
                    setWaiterLoading(false);
                  }}
                >
                  Call Waiter
                </Button>
              )
            ) : (
              <span />
            )}
            {!checkBill
              ? adminId !== "undefined" &&
                tableId !== "undefined" && (
                  <Button
                    type="primary"
                    onClick={() => setCheckBill(!checkBill)}
                  >
                    {role === "WORKER" ? "" : "Check Bill"}
                  </Button>
                )
              : adminId !== "undefined" &&
                tableId !== "undefined" && (
                  <Button
                    type="primary"
                    onClick={() => setCheckBill(!checkBill)}
                  >
                    Return to Menu
                  </Button>
                )}
          </div>
        </div>
        {adminId === "undefined" && tableId === "undefined" && (
          <Typography.Title
            level={3}
            style={{
              color: "red",
              backgroundColor: "yellow",
              textAlign: "center",
              width: "max-content",
              display: "flex",
              margin: "0 auto",
              padding: "2px 15px",
              borderRadius: "4px",
            }}
          >
            You cannot Place Order, Please Scan the Table QR Code to Place Order
          </Typography.Title>
        )}

        {checkBill ? (
          <div className="order-cards">
            <Typography.Title level={3} style={{ margin: "30px 0" }}>
              Active Orders
            </Typography.Title>
            <Row gutter={[30, 30]}>
              {activeOrders && activeOrders.length > 0 ? (
                activeOrders.map(
                  ({
                    products,
                    user,
                    userName,
                    amount,
                    flatDiscountPercentage,
                    originalAmount,
                  }) => {
                    return products.map((product) => {
                      let extraProductsPrice =
                        activeOrders &&
                        products &&
                        product.extraProducts.length > 0 &&
                        product.extraProducts.map((extra) => {
                          return extra.details.price;
                        });
                      console.log(extraProductsPrice);

                      let priceQuantity = parseInt(
                        product.product.price * product.quantity
                      );

                      return (
                        <Col xs={24} md={12} lg={8}>
                          <div className="order-card">
                            <p>
                              Name :{" "}
                              <span>{userName ? userName : user.name}</span>
                            </p>
                            <p>
                              Product :{" "}
                              <span>
                                {product.product && product.product.name}
                              </span>
                            </p>
                            <p>
                              Unit Price :{" "}
                              <span>
                                TL {product.product && product.product.price}
                              </span>
                            </p>
                            <p>
                              Quantity : <span>{product.quantity}</span>
                            </p>
                            <p>
                              Discount :{" "}
                              <span>{`${flatDiscountPercentage} %`}</span>
                            </p>
                            <p>
                              Extra Products :{" "}
                              <span>
                                TL{" "}
                                {extraProductsPrice === false
                                  ? 0
                                  : extraProductsPrice}
                              </span>
                            </p>
                            <p>
                              Original Price :{" "}
                              <span>
                                TL {originalAmount}
                                {/* TL{" "}
                              {extraProductsPrice === false
                                ? priceQuantity
                                : priceQuantity + parseInt(extraProductsPrice)} */}
                              </span>
                            </p>
                            <p>
                              Net Price :{" "}
                              <span>
                                TL {amount}
                                {/* TL{" "}
                              {extraProductsPrice === false
                                ? priceQuantity
                                : priceQuantity + parseInt(extraProductsPrice)} */}
                              </span>
                            </p>
                            {role === "WORKER" && (
                              <Button
                                type="primary"
                                onClick={() => {
                                  console.log(user);
                                  localStorage.setItem("userId", user._id);
                                  localStorage.setItem("userName", user.name);
                                }}
                              >
                                Order Again by this User
                              </Button>
                            )}
                          </div>
                        </Col>
                      );
                    });
                  }
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "250px",
                  }}
                >
                  <Empty description="No Active Orders" />
                </div>
              )}
            </Row>
          </div>
        ) : (
          <div className="category-cards">
            <Typography.Title level={3}>Categories</Typography.Title>
            <Row justify="center" gutter={[30, 30]}>
              {categories && categories.length > 0 ? (
                categories &&
                categories.length > 0 &&
                categories.map((category) => (
                  <Col xs={24} md={12} key={category._id}>
                    <Link to={`/user/category/${category._id}`}>
                      <div className="category">
                        <img src={category.image} alt="" />
                        <p>{category.name}</p>
                      </div>
                    </Link>
                  </Col>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                    width: "100%",
                  }}
                >
                  {/* <Spin size="large" /> */}
                  <Empty description="No Menu" />
                </div>
              )}
            </Row>
          </div>
        )}
        {/* <Typography.Title level={2}>Welcome to My Restaurant</Typography.Title> */}
        {adminDetails && (
          <div className="restaurant-info">
            <img
              src={adminDetails && adminDetails.logo}
              alt="restaurant-logo"
            />
            <div>
              <p>
                Venue Name : <span>{adminDetails && adminDetails.name}</span>
              </p>
              <p>
                Contact :{" "}
                <span>
                  {adminDetails && adminDetails.contactNumber
                    ? adminDetails.contactNumber
                    : "-"}
                </span>
              </p>
              <p>
                Email : <span>{adminDetails && adminDetails.email}</span>
              </p>
              <p>
                Location :{" "}
                <span>
                  {adminDetails &&
                    `${adminDetails.city}, ${adminDetails.country}`}
                </span>
              </p>
            </div>
          </div>
        )}

        {role === "USER" && (
          <div>
            <Typography.Title level={3} style={{ margin: "30px 0" }}>
              Active Promotions for You
            </Typography.Title>
            <Table
              columns={columns}
              className="promotion-table"
              scroll={{ x: 600 }}
              // loading={customerList && customerList.length > 0 ? false : true}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: true,
                position: ["bottomCenter"],
                pageSizeOptions: ["5", "10", "20", "30"],
              }}
              dataSource={
                userPromotions &&
                userPromotions.length > 0 &&
                userPromotions.map((details, i) => {
                  return {
                    key: i,
                    type: details.type,
                    percentage: details.percentage,
                    startDate: details.startDate
                      ? moment(details.startDate).format("D MMM YYYY")
                      : "-",
                    endDate: details.endDate
                      ? moment(details.endDate).format("D MMM YYYY")
                      : "-",
                    product: details.productName ? (
                      <span>
                        <b>{`${details.productName.toUpperCase()} ,`}</b> from
                        category <b>{`${details.category}`}</b>
                      </span>
                    ) : (
                      "-"
                    ),
                  };
                })
              }
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UserDashboard;
