import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory, Redirect, useParams } from "react-router-dom";
// import swal from 'sweetalert'

import logo from "../assets/qrlogo.png";
import { workerLogin, adminLogin } from "../redux";
import swal from "sweetalert";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  // const [admin, setAdmin] = useState(null);

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      adminLogin(id, history);
    }
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    setLoading(true);
    // await dispatch(userLogin(values, history, adminId, tableId));
    // if (admin && admin.admin.active && admin.active) {
    if (email && password) {
      await dispatch(workerLogin(values, history));
    } else {
      swal("", "Email or Password is missing", "error");
    }
    // } else {
    //   if (admin && admin.active && !admin.admin.active) {
    //     swal("Can't Login", 'This Admin is Inactive', 'error');
    //   } else if (admin && !admin.active && admin.admin.active) {
    //     swal("Can't Login", 'This Table is Inactive', 'error');
    //   } else if (admin && !admin.active && !admin.admin.active) {
    //     swal("Can't Login", 'Sorry! The Admin & Table is Inactive', 'error');
    //   }
    // }
    setLoading(false);
  };

  if (localStorage.hasOwnProperty("userId")) {
    return <Redirect to="/user" />;
  } else {
    return (
      <div className="signin">
        <Link to="/">
          <img src={logo} alt="logo" width="auto" height="100px" />
        </Link>
        <Form
          name="login"
          className="login-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <h2>Worker Login</h2>
          <Form.Item name="email" label="Email">
            <Input
              autoComplete="off"
              placeholder="Enter Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input.Password
              type="password"
              placeholder="Password..."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-btn"
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default SignIn;
